import React, {Component} from 'react';
import { connect } from 'react-redux';

import { CHANGE_PASSWORD } from 'Constants/actionConstants';

import Logo from 'Components/Logo';
import Input from 'Components/Input';
import Button from 'Components/Button';
import { withRouter } from 'react-router-dom';
import { deleteToken, clearStorage, getCompanyType, getUser } from 'Utils/storage';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                current_password: '',
                new_password: '',
                confirm_new_password: ''
            }
        }
    }

    onChange = (e)=> {
        const { data } = this.state;
        data[e.target.name] = e.target.value
        this.setState({ data })
    }

    changePassword = (e)=> {
        const { dispatch, history } = this.props;
        const { data } = this.state;
        e.preventDefault();
        dispatch({
            type: CHANGE_PASSWORD,
            data: {
                ...data,
                company_type: getCompanyType(),
                email: getUser().email,
                module_id: 3
            },
            onSuccess: () => {
                history.push('/');
                deleteToken();
                clearStorage();
            }
        })
    }

    render() {
        return (
            <div className="mwt-350 center pr">
                <form className="form pt-50 flex flex-column" onSubmit={this.changePassword}>
                    <div className="tCenter wt-70 flex flex-center pt-50 flex-self-center">
                        <Logo className="ht-130"/>
                    </div>
                    <Input containerClassName="mb-20" name="current_password" type="password" value={this.state.current_password} placeholder="Old Password" onChange={this.onChange}/>
                    <Input containerClassName="mb-10" name="new_password" type="password" value={this.state.new_password} placeholder="New Password" onChange={this.onChange}/>
                    <Input containerClassName="mb-10" name="confirm_new_password" type="password" value={this.state.confirm_new_password} placeholder="Confirm Password" onChange={this.onChange}/>
                    <Button type="submit" className="mb-15" value="Change Password"/>
                </form>
            </div>
        )
    }
}

export default connect()(withRouter(ChangePassword));
