import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Sticky from 'Components/Sticky';
import Tabs from 'Components/Tabs';
import Dropdown from 'Components/SearchDropdown';
import ActionLoader from 'Components/ActionLoader';
import DashboardCardContainer from 'Components/DashboardCard/DashboardCardContainer';
import { getCompanyType, setBoundType, setPageOffset, getUser, storeCompanyType, storeToken, storeCompanyId, getToken } from 'Utils/storage';
import { SEEKER, PROVIDER, trackingMode, TRANSPORT_TYPE, TTBIL, trackingModeAWL, AWL } from 'Constants/commonConstants';
import TruckAction from 'Components/TruckActions';
import Checkbox from 'Components/Checkbox';
import { ReactComponent as DropdownIcon } from './icons/drop-up.svg';
import ActionMap from './actions_map';
import SchedulerDashboard from 'Components/SchedulerDashboard';
import FOISDashboard from 'Components/FOISDashboard';

import {
     GET_TRIPS,
     GET_TRANSPORTER_SEACH,
     GET_PLANT_SEACH,
     GET_ITEMS_SEACH,
     GET_DASHBOARD,
     GET_DASHBOARD_DETAILS,
     GET_ACTION_DETAILS,
     GET_EXCEPTION_TRIP_DETAILS,
     GET_TRIP_STATUS_COUNT,
     GET_SCHEDULER_DASHBOARD,
     GET_LOCATIONS,
     GET_TRACKING_DETAIL,
     GET_BRANCH,
     DOWNLOAD_TRIP,
     GET_FOIS_TEMPLATE,
     POST_FOIS_UPLOAD_FILE,
     GET_FOIS_DETAILS,
     GET_FOIS_TRACKING_DATA,
     GET_FOIS_STATION_DATA,
     POST_FOIS_FNR,
     GET_FOIS_COUNT,
     GET_FOIS_MOVEMENT_TYPE,
     EDIT_FNR_DATA,
     LOGIN_WITH_SSO,
     GET_UNTRACKED_LOG_DETAILS
} from 'Constants/actionConstants';
import TripsListing from 'Pages/Trips/TripsListing/index';
const flattenDeep = (arr1)=> {
    return arr1.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
 }


class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      serviceType: 0,
      boundType: 0,
      limit: 10,
      offset: 0,
      transporterSearch: '',
      plantSearch: '',
      itemsSearch: '',
      search: '',
      trip_mode: TTBIL.includes(getUser().company_id) ? 2 : 1,
      show: false,
      truckColor: 'black',
      truckType: '',
      transport_type: TRANSPORT_TYPE[0].id,
      fnrList: [],
      trackFNR: {},
      fnrData: 0,
      fnrMsg: '',
      isTracked: false,
      station: [],
      isFilter: false,
      isSSO: false,
      genericSearch: {
        source_location: '',
        src_group_id: null,
        dest_location: '',
        dest_group_id: null,
        status_text: '',
        to_date_search: null,
        from_date_search: null,
        di_time: null,
        search: ''
      },
      untrackedSummary:[],
      statuses: [],
    }
  }
  searchTrips = (pagination=false, searchText, isDownload=false)=> {
    const { dispatch } = this.props;
    const { offset, limit, boundType, serviceType, transporterSearch, plantSearch, itemsSearch, searchState, transport_type, genericSearch } = this.state;
    const params = {
      offset: isDownload ? 0 : offset,
      limit: isDownload ? 500 : limit,
      boundType: boundType > 0?boundType:"",
      serviceType: serviceType > 0?serviceType: "",
      transporterSearch,
      plantSearch,
      itemsSearch,
      search: searchText?searchText:searchState,
      transport_type: transport_type,
    }
    if(isDownload) {
      params.download = 1;
      params.trip_mode = 2;
      params.source_location = genericSearch.source_location;
      params.src_group_id = genericSearch.src_group_id;
      params.dest_location = genericSearch.dest_location;
      params.dest_group_id = genericSearch.dest_group_id;
      params.status_text = genericSearch.status_text;
      params.to_date_search = genericSearch.to_date_search;
      params.from_date_search = genericSearch.from_date_search;
      params.di_time = genericSearch.di_time;
      params.search = genericSearch.search;
      dispatch({
        type: DOWNLOAD_TRIP,
        params
      })
    } else {
        dispatch({
          type: GET_TRIP_STATUS_COUNT,
          params
        });

        dispatch({
            type: GET_TRIPS,
            params
        });
    }
  }
  filterDashboard = ()=> {
    const { dispatch } = this.props;
    const { boundType, serviceType, transporterSearch, plantSearch, genericSearch, itemsSearch, trip_mode, transport_type } = this.state;
    dispatch({
        type: GET_DASHBOARD,
        params: {
            boundType: boundType > 0?boundType:"",
            serviceType: serviceType > 0?serviceType: "",
            transporterSearch,
            transport_type,
            plantSearch,
            genericSearch,
            itemsSearch,
            trip_mode,
        }
    });
  }
  genericSearch = (searchText)=> {
    this.setState({
      search: searchText
    }, this.searchTrips);
  }
  searchTransporters = (value)=> {
    this.setState({
      transporterSearch: value
    }, ()=> {
      if(!value) {
        this.searchTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_TRANSPORTER_SEACH,
        transporterSearch: value
      })
    });
  }
  selectTransporter = (value)=> {
    this.setState({
      transporterSearch: value
    },this.filterDashboard);
  }
  searchPlants = (value)=> {
    this.setState({
      plantSearch: value
    }, ()=> {
      if(!value) {
        this.searchTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_PLANT_SEACH,
        plantSearch: value
      })
    });
  }
  handleClearFilter = (type) => {
    this.setState({
      [type]: ''
    },this.filterDashboard);
  }
  selectPlant = (value)=> {
    this.setState({
      plantSearch: value
    },this.filterDashboard);
  }
  searchItems = (value)=> {
    this.setState({
      itemsSearch: value
    }, ()=> {
      if(!value) {
        this.searchTrips();
        return;
      }
      const { dispatch } = this.props;
      dispatch({
        type: GET_ITEMS_SEACH,
        itemsSearch: value
      })
    });
  }

  changeOffset = (offset)=> {
    this.setState({
      offset
    }, ()=> {
      this.getSchedulerDetails();
    })
  }

  onClickPagination = (type) => {
    let { offset } = this.state;
    if( type == 'previous' && offset != 0) {
      offset -= 20;
      this.setState({
        offset
      }, ()=> {
        this.getSchedulerDetails()
      })
    } else if ( type == 'next') {
      offset += 20;
      this.setState({
        offset
      }, ()=> {
        this.getSchedulerDetails()
      })
    }
  }

  changeSearchText = (e)=> {
    this.setState({
        searchText: e.target.value
    }, () => {
      this.onSearch(this.state.searchText);
    });
  }

  onSearch = (value)=>{
    localStorage.setItem('teg-component', true);
    location.href="/trips?search=" + value;
  }

  onEnter = (e)=> {
    if(e.nativeEvent.which === 13) {
        this.props.onSearch(e.target.value);
    }
  }
  
  getTripDetails = () => {
    const { dispatch, match } = this.props;
    dispatch({
      type: GET_TRACKING_DETAIL,
      tripId: match.params.id
    });
  }

  downloadFOISTemplate = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_FOIS_TEMPLATE,
    })
  }

  uploadFOISData = (file) => {
    const { dispatch } = this.props;
    dispatch({
      type: POST_FOIS_UPLOAD_FILE,
      file,
      onSuccess: () => {
        this.getFOISCount();
        this.getFOISData(2);
      }
    })
  }

  editFNRData = (fnr, updated) => {
    const { dispatch } = this.props;
    const data = {
      fnr_no_scrape_time: updated.fnr_no_scrape_time ? updated.fnr_no_scrape_time : 0,
      fnr_no: fnr.fnr_no,
      is_delete: false,
      freight_details: {
        loaded_weight: updated.loaded_weight,
        movement_type: updated.movement_type,
        loading_start: updated.loading_start ? updated.loading_start : 0,
        loading_end: updated.loading_end ? updated.loading_end : 0,
        total_loading_time: updated.total_loading_time ? updated.total_loading_time : 0,
        destination_place_date_time: updated.destination_place_date_time ? updated.destination_place_date_time : 0,
        unloading_start: updated.unloading_start ? updated.unloading_start : 0,
        unloading_end: updated.unloading_end ? updated.unloading_end : 0,
        total_unloading_time: updated.total_unloading_time ? updated.total_unloading_time : 0,
        // destination_release_time: updated.destination_release_time ? updated.destination_release_time : 0,
        
      }
    }
    dispatch({
      type: EDIT_FNR_DATA,
      fnr_number: fnr.fnr_no,
      data,
      onSuccess: () => {
        this.getFOISCount();
        this.getFOISData(6);
      }
    })
  }

  deleteFNR = (fnr) => {
    const { dispatch } = this.props;
    const data = {
      is_delete: true
    }
    dispatch({
      type: EDIT_FNR_DATA,
      fnr_number: fnr,
      data,
      onSuccess: () => {
        this.getFOISCount();
        this.getFOISData(2);
      }
    })
  }

  getFOISCount = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_FOIS_COUNT,
      onSuccess: (data) => {
        this.setState({ statuses: data.data.tracked_trips})
      }
    })
  } 

  getFOISMovementType = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_FOIS_MOVEMENT_TYPE
    })
  }

  getFOISData = (fnrType, search, start_date, end_date, from_station, to_station, withData) => {
    const { dispatch } = this.props;
    const { statuses } = this.state;
    const fromStationCode = from_station && from_station.split('-');
    const toStationCode = to_station && to_station.split('-');
    const data = {
      limit: 50,
      offset: 0,
      fnr_no: search,
      type: fnrType,
      start_date,
      end_date,
      from_station: from_station ? (fromStationCode[0] || fromStationCode[1]) : undefined,
      to_station: to_station ? (toStationCode[0] || toStationCode[1]) : undefined,
      withData
    }
    dispatch({
      type: GET_FOIS_DETAILS,
      data,
      onSuccess: (data) => {
        this.getFOISCount();
        if(data.data){
          this.setState({fnrData: data.data, isTracked: false, fnrCount: data.data.filter_count, fnrMsg: ''})
        }
      }
    })
  }

  createFNR = (fnr) => {
    const { dispatch } = this.props;
    let { fnrData } = this.state;
    const data = {
      fnr_no: fnr.adhoc_fnr_no,
      movement_type: fnr.fnrMovementType,
      no_of_wagons: fnr.no_of_wagon,
      loaded_weight: fnr.loaded_weight
    }
    dispatch({
      type: POST_FOIS_FNR,
      data,
      onSuccess: (data) => {
        if(data.type !== 'N/A'){
          fnrData['fnr_trips'][0] = data;
          this.setState({fnrData})
        } else {
          this.setState({fnrMsg: "Tracking is enabled for the FNR number. Please check the status after some time"})
        }
        this.getFOISData(2);
      }
    })
  }

  getFOISTrackingData = (fnr_number) => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_FOIS_TRACKING_DATA,
      fnr_number,
      onSuccess: (data) => {
        if(data){
          this.setState({trackFNR: data.data, isTracked: true})
        }
      }
    })
  }

  getFOISStation = (station_search) => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_FOIS_STATION_DATA,
      station_search,
      onSuccess: (data) => {
        if(data){
          let stat = [];
          data.data.map((stn) => {
            stat.push(`${stn.station_name}-${stn.station_code}`)
          })
          this.setState({station: stat})
        }
      }
    })
  }

  

  getSchedulerDetails = () => {
    // GET_SCHEDULER_DASHBOARD
    const { dispatch } = this.props;
    const { offset, genericSearch } = this.state;
    const data = {
      trip_mode: 2,
      source_location: genericSearch.source_location,
      src_group_id: genericSearch.src_group_id,
      dest_location: genericSearch.dest_location,
      dest_group_id: genericSearch.dest_group_id,
      status_text: genericSearch.status_text,
      to_date_search: genericSearch.to_date_search,
      from_date_search: genericSearch.from_date_search,
      di_time: genericSearch.di_time,
      search: genericSearch.search,
      offset,
      limit: 20
    }
    if(this.state.trip_mode === 2){
      dispatch({
        type: GET_SCHEDULER_DASHBOARD,
        data
      })
    }
    
  }

  searchFields = (value, key) => {
    let { genericSearch } = this.state;
    if(key == 'dest_location'){
      genericSearch[key] = value.plant_code || value.name;
      genericSearch['dest_group_id'] = value.group_id;
    } else if(key == 'source_location'){
      genericSearch[key] = value.name;
      genericSearch['src_group_id'] = value.group_id;
    }
    else {
      genericSearch[key] = (key == 'status_text' && value == "all") ? undefined : value;
    }
    this.setState({genericSearch})
    this.getSchedulerDetails();
    this.filterDashboard();
  }

  
  handleRouteDataChange = (value) => {
    const { dispatch } = this.props;
        dispatch({
            type: GET_LOCATIONS,
            param: {
              use_search_term: value
            }
        }); 
  }
  

  getBranchLocationType = (value) => {
    const { dispatch } = this.props;
        dispatch({
            type: GET_BRANCH,
            param: {
                search: value
            }
        }); 
  }

  selectItem = (value)=> {
    this.setState({
      itemsSearch: value
    },this.filterDashboard);
  }
  selectServiceType = (serviceType)=> {
    this.setState({
      serviceType,
      boundType: serviceType===0?0:this.state.boundType
    }, this.filterDashboard);
  }

  selectTrackingMode = (trip_mode) => {
    this.setState({
      trip_mode,
      boundType: this.state.boundType
    }, this.filterDashboard);
  }

  selectTransportType = (transport_type) => {
    this.setState({
      transport_type,
      boundType: this.state.boundType
    }, () => {
      this.filterDashboard();
      this.getActionDetails();
    });
  }
  
  getDashboardDetails = (boundType, boundName, statusType, locationTypeStatus)=> {
    const { dispatch, history } = this.props;
    const { serviceType, transporterSearch, plantSearch, genericSearch, itemsSearch, trip_mode, transport_type } = this.state;
    //setBoundType({ boundType, statusType });
    setPageOffset({
      offset: 0 ,
      serviceType : this.state.serviceType,
      boundType: boundType,
      truck_location_status: statusType,
      trip_mode,
      transport_type,
      location_type_status: locationTypeStatus,
      group_id: genericSearch.dest_group_id
    });
    // dispatch({
    //     type: GET_DASHBOARD_DETAILS,
    //     params: {
    //         boundType,
    //         statusType,
    //         serviceType: serviceType > 0?serviceType: "",
    //         transporterSearch,
    //         plantSearch,
    //         itemsSearch,
    //         boundName
    //     }
    // });
    if([1].indexOf(statusType) == -1) {
      history.push('/trips');
    }
  }

  getActionDetails = () => {
    const { dispatch } = this.props;
    const { transport_type, trip_mode } = this.state;
    dispatch({ type: GET_ACTION_DETAILS , data: { trip_mode : trip_mode, transport_type: transport_type } });
  }

  getRowDetails = (data) => {
        return (
          <Fragment>
          {data && data.length > 0 && data.map((list, index) => {
        return(<div  className={'flex trip-row flex-middle pb-5 pt-5'}
          onClick={() => {
          this.setState(prevState => ({
            show: !prevState.show
          }))
        }} key={index}>
          <div className={'wt-200 mr-5'}>{list.do_no}</div>
          <div className={'wt-350 mr-5'}>
            <span>{list.vehicle_details.vehicle_type}</span><br/>
            <span style={{color: `${list.status_code}`}}>{list.vehicle_details.vehicle_no}</span><br/>
            <span>{list.vehicle_details.body_type}</span>
          </div>
          <div className={'wt-300 mr-5'}>{`${list.route_details[0].city} - ${list.route_details[list.route_details.length-1].city}`}</div>
          <div style={{color: `${list.status_code}`}} className={'wt-200 mr-5'}>
            {
              list.driver_details && list.driver_details.length > 0 && list.driver_details.map((driver, ind)=> {
                return(
                      <div key={ind}>
                        <span>
                          {(driver.firstName || driver.lastName) ? `${driver.firstName} ${driver.lastName}` : driver.name}
                        </span>
                        <br/>
                        <span>{driver.number}</span><br/>
                      </div>
                )
              })
            }
          </div>
          <div className={'wt-300 mr-5'}>
              <span>{list.transporter_details.name}</span><br/>
              <span>{list.transporter_details.email}</span><br/>
          </div>
      </div>)})}
      {/* console.log(getCookie('user-type')) */}
      </Fragment>)
  }

  onCountClick = (statusType, sub_type, location_type_status, interval) => {
    const { history } = this.props;
    const { boundType , serviceType, trip_mode, transport_type } = this.state;
    setPageOffset({
      interval,
      serviceType,
      boundType,
      trip_mode,
      sub_type,
      location_type_status,
      transport_type,
      offset: 0,
      truck_location_status: statusType,
    });
    history.push('/trips');
  }

  onClickExceptions = (sub_type, range, truckColor, subData, subType) => {
    const { dispatch } = this.props;
    const { trip_mode, transport_type } = this.state;
    const data = {
      sub_type,
      trip_mode,
      transport_type
    }
    if(subType === 66){
      data.in_transit_check = 1;
    }
    if (range.key) {
      let interval = range.key.replace('>', '').split('-');
      data.from = Number(interval[0]);
      data.to = Number(interval[1] || 100000);
      this.setState({ truckColor : truckColor, truckType: `${subData} (${range.key} ${subData !== 'DEVIATED' ? 'hours':'km'})` })
      dispatch({ type: GET_EXCEPTION_TRIP_DETAILS,  data });
    }
  }

  setCounts = (tabs, counts) => {
    tabs.map((item) => {
      item.count = counts[item.id] || 0;
      return;
    });
  }

  ttbilFilter = () => {
    this.setState({ isFilter: !this.state.isFilter})
  }

  getUntrackedSummary =()=>{
    const{dispatch}=this.props;
    dispatch({
      type: GET_UNTRACKED_LOG_DETAILS,
      params: {seeker_id: getUser().company_id},
      onSuccess: ((data) => {
          this.setState({
            untrackedSummary:data
          });
      })
  })
  }
  shouldRenderUntrackedSummary=(untrackedSummary)=>{
    const validUntrackedSummaryResponseArray = Array.isArray(untrackedSummary) && untrackedSummary.length>0;
    if(validUntrackedSummaryResponseArray){
      return true;
    }
    return false;
  }

  render() {
    const {
      tripsCount,
      ftlCount,
      lclCount,
      courierCount,
      containerCount,
      transporterSearchItems,
      plantSearchItems,
      itemsSearchItems,
      dashboard,
      isDashboardLoaded,
      inboundTrips,
      outboundTrips,
      priorityTrips,
      inboundTripsLoaded,
      outboundTripsLoaded,
      priorityTripsLoaded,
      actionDetails,
      expectionTripDetails,
      tripModeCount,
      boundTypeCount,
      transportTypeCount
    } = this.props;
      const inboundStatuses = dashboard && dashboard.inbound?flattenDeep([dashboard.inbound.total_trips, dashboard.inbound.tracked_trips, dashboard.inbound.untracked_trips]):[];
      const outboundStatuses = dashboard && dashboard.outbound?flattenDeep([dashboard.outbound.total_trips, dashboard.outbound.tracked_trips, dashboard.outbound.untracked_trips]):[];
      const priorityStatuses = dashboard && dashboard.priority?flattenDeep([dashboard.priority.total_trips, dashboard.priority.tracked_trips, dashboard.priority.untracked_trips]):[];
      const companyType = getCompanyType();
    this.setCounts(trackingMode, tripModeCount);
    this.setCounts(TRANSPORT_TYPE, transportTypeCount);
    // this.setCounts(trackingModeAWL, tripModeCount);
    return (
      <Sticky activeLink={'dashboard'} onSearch={this.genericSearch} initialSearchValue={''} isSearch={false}>
        <div className="p5 pr-30">
        <section className="pl-15 mb-10 flex flex-between">
          {companyType === SEEKER &&
            <div className="flex-grow mr-10 dashboard-tab col-3">
              {AWL.includes(getUser().company_id) ? 
              <Tabs
                tabs={trackingModeAWL}
                activeTabId={this.state.trip_mode}
                selectTab = {this.selectTrackingMode}
                // isAlternateName={TTBIL.includes(getUser().company_id)}
              />
              :
              <Tabs
                tabs={trackingMode}
                activeTabId={this.state.trip_mode}
                selectTab = {this.selectTrackingMode}
                isAlternateName={TTBIL.includes(getUser().company_id)}
              />}
              
            </div>
          }
          <div className="flex-grow mr-10 dashboard-tab">
            <Tabs
              tabs={TRANSPORT_TYPE}
              activeTabId={this.state.transport_type}
              selectTab = {this.selectTransportType}
            />
          </div>
        </section>
          
          {isDashboardLoaded && <Fragment>
            {!(TTBIL.includes(getUser().company_id) && this.state.trip_mode === 2) &&
            <section className="pl-15">
                <DashboardCardContainer isDataLoaded={inboundTripsLoaded} trips={inboundTrips} onSelect={this.getDashboardDetails.bind(this,1,'inbound')} header={'Inbound'} statuses={inboundStatuses}/>
            </section>}
            <section className="pl-15">
                <DashboardCardContainer isDataLoaded={outboundTripsLoaded} trips={outboundTrips}  onSelect={this.getDashboardDetails.bind(this,2,'outbound')} header={'Outbound'} statuses={outboundStatuses} ttbilFilter={this.ttbilFilter} trip_mode={this.state.trip_mode}/>
            </section>
          </Fragment>}
          {!isDashboardLoaded && <ActionLoader  fixed={true}/>}
          {TTBIL.includes(getUser().company_id) && this.state.trip_mode === 2 && 
            <SchedulerDashboard 
              schedulerList={this.props.schedulerList} 
              handleRouteDataChange={this.handleRouteDataChange} 
              getBranchLocationType={this.getBranchLocationType}
              branchList={this.props.branchList}
              diTimeList={this.props.diTimeList}
              locations={this.props.locations} 
              searchFields={this.searchFields} 
              schedulerCount={this.props.schedulerCount} 
              changeOffset={this.changeOffset} 
              onClickPagination={this.onClickPagination} 
              offset={this.state.offset}
              searchTrips={this.searchTrips}
              changeSearchText={this.changeSearchText} 
              onEnter={this.onEnter}
              mapLocation={this.props.mapLocation}
              searchWithTrackingType={this.searchWithTrackingType}
              currentLocation={this.props.currentLocation}
              operatorTypes={this.props.operatorTypes}
              isFilter={this.state.isFilter}
            />
          }
          {AWL.includes(getUser().company_id) && this.state.trip_mode === 3 && 
          <FOISDashboard 
            downloadFOISTemplate={this.downloadFOISTemplate}
            uploadFOISData={this.uploadFOISData}
            getFOISTrackingData={this.getFOISTrackingData}
            getFOISData={this.getFOISData}
            fnrData={this.state.fnrData}
            trackFNR={this.state.trackFNR}
            isTracked={this.state.isTracked}
            fnrCount={this.state.fnrCount}
            getFOISStation={this.getFOISStation}
            station={this.state.station}
            createFNR={this.createFNR}
            statuses={this.state.statuses}
            fnrMsg={this.state.fnrMsg}
            foisMovementType={this.props.foisMovementType}
            deleteFNR={this.deleteFNR}
            editFNRData={this.editFNRData}
          />}
          {this.state.trip_mode === 1 && <div className={'mt-10 ml-20 mr-20 flex'}>
            {
              actionDetails['general'] && actionDetails['general'].map((data, index) => {
                return (
                  <>
                  {(data.data || []).length > 0 && <div className={'col-4 box-shadow mr-10 p-10 action-item'}>
                    <div className={'fs-16 fBolder mb-10'}>
                      {data.status_text}
                    </div>
                    {
                      data.data && data.data.map((interval, index) => {
                        return (
                          <div className={'flex flex-between pb-5 pt-5 bb-theme-light'} >
                            <div className={'fBolder'} style={{'color': interval.color}}>
                              <span className={'consent-status'} style={{backgroundColor: interval.color}}></span>
                              <span className={'ml-5 mr-5 theme-color fBolder curP'} onClick={() => this.onCountClick(data.type, interval.sub_type, data.location_type_status, interval.key)}>{`(${interval.count})`}</span>
                              {`${interval.key} hours`}
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>}
                  </>
                )
              })
            }
            
            {
              actionDetails['exceptions'] && actionDetails['exceptions'].length > 0 && <div className={'col-4 box-shadow mr-10 p-10 bg-light-red action-item'}>
              <div className={'fs-16 fBolder mb-10'}>{'Exception Alerts'}</div>
                {
                  actionDetails['exceptions'].map((data, index) => {
                    return (
                      <div className={'pb-5 pt-5 bb-theme-light'} key={`${data.status_text}-${index}`}>
                        <div className={'flex flex-between'}>
                          <div className={'fBolder error-color'}>
                            <span className={'consent-status curP'} style={{backgroundColor: data.color}}></span>
                            <span className={'ml-5'} style={{'color': data.color}}>{`${data.status_text} (${data.count})`}</span>
                          </div>
                        </div>
                        {
                          data.data && data.data.map((interval, index) => {
                            return(
                              <div className={'flex flex-between ml-20'} key={index}>
                                <div className={'fBolder'} style={{'color': interval.color}}>
                                  <span className={'consent-status'} style={{backgroundColor: interval.color}}></span>
                                  <span className={'ml-5 mr-5 theme-color fBolder curP'} onClick={() => this.onClickExceptions(interval.sub_type, interval, interval.color, data.status_text, data.sub_type)}>{`(${interval.count})`}</span>
                                  {`${interval.key} ${['DEVIATED','In Transit Above 5 Days'].includes(data.status_text) ? 'km' :'hours'}` }
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            }
            {this.shouldRenderUntrackedSummary(this.state.untrackedSummary )&&
              <div className={'col-4 box-shadow mr-10 p-10 bg-light-red action-item'}>
              <div className={'fs-16 fBolder mb-10'}>{'Untracked Summary'}</div>
                {
                  this.state.untrackedSummary.map((data, index) => {
                    return (
                      <div className={'pb-5 pt-5 bb-theme-light'} key={`${data.status_text}-${index}`}>
                        <div className={'flex flex-between'}>
                          <div className={'fBolder'}>
                            <span className={'consent-status curP'} style={{backgroundColor: "black"}}></span>
                            <span className={'ml-5'} style={{'color': data.color}}>{`${data.untracked_message}`}</span><span className={'ml-5'} style={{'color': "blue"}}>{`(${data.count})`}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            }
          </div>}
         {expectionTripDetails && expectionTripDetails.length > 0 && <div className={'mt-10 ml-20'}>
            <ActionMap trips={expectionTripDetails} truckColor={this.state.truckColor} truckType={this.state.truckType}/>
          </div>}

        </div>
      </Sticky>
    );
  }
  componentDidMount() {
    const { dispatch } = this.props;
    let url = window.location.href;
    let params = url.split("?");
    let userData = {};
    const isSSOUser = localStorage.getItem('isSSOUser');
    const SSOSessionSaved = localStorage.getItem('teg-sso-session');
    const isToken = getToken();
    const intValues = ['company_type'];
    if(params && params.length > 1 && !isToken){
      let objects = params[1].split("&");
      (objects || []).map((obj) => {
        let data = obj.split("=");
        if(['company_type'].includes(intValues)){
          userData[data[0]] = Number(data[1]);
        } else {
          userData[data[0]] = data[1];
        }
        if(data[0] === "token"){
          storeToken(data[1]);
        }
        else if(data[0] === "company_type"){
          storeCompanyType(Number(data[1]));
        }
        else if(data[0] === "company_id"){
          storeCompanyId(data[1]);
        }
      })
    }
    if(isSSOUser && SSOSessionSaved !== 'true'){
      dispatch({
        type: LOGIN_WITH_SSO,
        onSuccess: () => {
          this.searchTrips(false);
          this.filterDashboard();
          this.getActionDetails();
          this.getSchedulerDetails();
          this.getBranchLocationType();
          this.handleRouteDataChange("0");
          if(TTBIL.includes(getUser().company_id)){
            this.intervalTripId = setInterval(() => this.getSchedulerDetails(), 10000);
          }
          if(AWL.includes(getUser().company_id)){
            this.getFOISData(6);
            // this.getFOISCount();
            this.getFOISMovementType();
          }
          localStorage.setItem('teg-sso-session', true)
        }
      })
    }
    else{
      this.searchTrips(false);
      this.filterDashboard();
      this.getActionDetails();
      this.getSchedulerDetails();
      this.getBranchLocationType();
      this.handleRouteDataChange("0");
      if(TTBIL.includes(getUser().company_id)){
        this.intervalTripId = setInterval(() => this.getSchedulerDetails(), 10000);
      }
      if(AWL.includes(getUser().company_id)){
        this.getFOISData(6);
        // this.getFOISCount();
        this.getFOISMovementType();
      }
      this.getUntrackedSummary();
    }
  }


  componentWillUnmount = () => {
    clearInterval(this.intervalTripId);
  }

}

const mapStateToProps = ({trips}) => {
  return {
    trips: trips.trips.data,
    isTripsLoaded: trips.trips.isDataLoaded,
    containerCount : trips.trips.containerCount,
    courierCount :trips.trips.courierCount,
    ftlCount : trips.trips.ftlCount,
    lclCount : trips.trips.lclCount,
    tripsCount : trips.trips.tripsCount,
    inboundCount : trips.trips.inboundCount,
    outboundCount : trips.trips.outboundCount,
    filterCount: trips.trips.filterCount,
    transporterSearchItems: trips.trips.transporterSearch || [],
    plantSearchItems: trips.trips.plantSearch || [],
    itemsSearchItems: trips.trips.itemsSearch || [],
    dashboard: trips.dashboard.data || {},
    isDashboardLoaded: trips.dashboard.isDataLoaded,

    inboundTrips: trips.dashboardDetails.inbound.data || [],
    outboundTrips: trips.dashboardDetails.outbound.data || [],
    priorityTrips: trips.dashboardDetails.priority.data || [],

    inboundTripsLoaded: trips.dashboardDetails.inbound.isDataLoaded,
    outboundTripsLoaded: trips.dashboardDetails.outbound.isDataLoaded,
    priorityTripsLoaded: trips.dashboardDetails.priority.isDataLoaded,
    schedulerList: trips.dashboardDetails.schedulerDetails || [],
    schedulerCount: trips.dashboardDetails.schedulerCount || 0,
    branchList: trips.branchList || [],
    diTimeList: trips.diTimeList || [],
    locations: trips.locations.data,
    currentLocation: trips.currentLocation,
    mapLocation: trips.mapLocation || null,
    operatorTypes: trips.operatorTypes || [],
        
    actionDetails: trips.actionDetails,
    expectionTripDetails: trips.expectionTripDetails,
    tripModeCount: trips.tripModeCount || {},
    transportTypeCount: trips.transportTypeCount || {},
    boundTypeCount: trips.boundTypeCount || {},
    foisMovementType: trips.foisMovementType || [],
  }
};


export default connect(
  mapStateToProps
)(withRouter(Dashboard));
