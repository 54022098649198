import { takeLatest, takeEvery,call, put, all } from "redux-saga/effects";
import sagasManager from "Utils/sagasManager";

import {
    getTrips,
    getTripDetail,
    searchTransporters,
    searchPlants,
    searchItems,
    searchCustomers,
    updateTrackingPreferences,
    getTrackingDetails,
    getDetailedLog,
    downloadLocationLogs,
    getLocations,
    getDashboard,
    addTrip,
    getDashboardDetails,
    getMultipleTrips,
    searchOnMap,
    downloadAddTripTemplate,
    uploadTrips,
    postManuatlTracking,
    postTransporterDetails,
    getProviders,
    updateTripCancellation,
    getGPSVendors,
    getHolistics,
    getCurrentLocation,
    getTATDetails,
    getSharedDetails,
    postSharedDetails,
    confirmAtOrigin,
    getActionDetails,
    getExceptionTripDetails,
    getVehiclesList,
    getVehicleBodyTypes,
    getVehicleDetailOptions,
    getVehicleData,
    postVehicleData,
    getSeekers,
    getOpertorTypes,
    getNumbersOpertorType,
    getLocationType,
    createLocation,
    getTripStatusCount,
    downloadTrip,
    getDONoBasedOnLocation,
    getSchedulerDetails,
    getBranchLocationType,
    getSchedulerRouteDetails,
    downloadFOISTrackingTemp,
    uploadFOISTracking,
    getFOISDetails,
    getFOISTrackingData,
    getFOISStationData,
    postAdhocFNR,
    getFOISDashboardCount,
    getFOISMovementData,
    reminderEmail,
    editFOISTrackingData,
    downloadMilestoneTemp,
    uploadOrders,
    generateDelayReasonTemplate,
    uploadDelayReasons,
    getMilestoneOverviewDetails,
    getMilestoneDetails,
    getMilestoneDocketList,
    getVehicleRemarks,
    confirmRejectVehicle,
    downloadLSPMilestoneTemp,
    uploadLSPDetails,
    uploadPODDetails,
    getServiceProvidersList,
    getReasonsList,
    CancelPickupRequest,
    getMilestoneReservedLrList,
    realocateLsp,
    getAuditListing,
    getAuditDetails,
    completeAudit,
    successToast,
    movetoException,
    getMovementType,
    getShortageType,
    getVehicleDetails,
    getMultimodalTrackingDetails,
    downloadPODUploadedFiles,
    getMileStoneLocationsDropdownList,
    getTripConsigneeDropdownList,
    downloadPickupRequestLogs,
    getTripPlantsAndDepotDropdownList,
    getTripTransportersDropdownList,
    getApiLogTypesList,
    getApiLogDetails,
    getTripServiceTypeDropdownList,
    getAuditListCount,
    getMilestoneLrCountDetails,
    getBookingOrderFailedDownlaods,
    getFastTagDetails,
    downloadFasttagLogs,
    getTrackedLogDetails,
    getUntrackedLogDetails
} from 'Sagas/trips';

import {
    signin, signout, changePassword, forgotPassword, requestOTP,
    loginWithOTP, loginWithSSO, ssoUserValidate, ssoDeleteToken, ssoConfig
} from 'Sagas/authentication';
import { getBroadcastMsg, getUserSession } from 'Sagas/common';

import {
    SIGN_IN,
    SIGN_OUT,
    GET_TRIPS,
    GET_TRIP_DETAIL ,
    GET_TRANSPORTER_SEACH,
    GET_PLANT_SEACH,
    GET_ITEMS_SEACH,
    GET_CUSTOMER_SEARCH,
    UPDATE_TRACKING_PREFERENCES,
    GET_TRACKING_DETAIL,
    GET_DETAILED_LOG,
    GET_LOCATIONS,
    GET_DASHBOARD,
    GET_DASHBOARD_DETAILS,
    GET_MULTIPLE_TRIPS,
    ADD_TRIP,
    SEARCH_ON_MAP,
    GET_ADD_TRIP_TEMPLATE,
    UPLOAD_TRIPS,
    POST_MANUAL_TRACKING,
    POST_TRANSPORTER_DETAILS,
    GET_PROVIDERS,
    CANCEL_TRIP,
    GET_GPS_VENDORS,
    GET_HOLISTICS,
    GET_CURRENT_LOCATION,
    GET_TAT_DETAILS,
    GET_SHARE_DETAILS,
    SAVE_SHARE_DETAILS,
    CONFIRM_AT_ORIGIN,
    GET_ACTION_DETAILS,
    GET_EXCEPTION_TRIP_DETAILS,
    GET_VEHICLE_TYPES,
    GET_VEHICLE_BODY_TYPES,
    GET_VEHICLE_DETAIL_OPTIONS,
    GET_VEHICLE_DATA,
    POST_VEHICLE_DATA,
    CHANGE_PASSWORD,
    FORGOT_PASSWORD,
    GET_SEEKERS,
    GET_OPERATOR_TYPE,
    GET_NUMBERS_OPERATOR_TYPE,
    GET_LOCATION_TYPE_FILTER,
    DOWNLOAD_LOCATION_LOGS,
    GET_BROADCAST_MSG,
    CREATE_LOCATION,
    GET_TRIP_STATUS_COUNT,
    DOWNLOAD_TRIP,
    GET_DO_NO_BASED_ON_FROM_LOC,
    GET_SCHEDULER_DASHBOARD,
    GET_BRANCH,
    GET_SCHEDULER_ROUTE_DETAILS,
    GET_FOIS_TEMPLATE,
    POST_FOIS_UPLOAD_FILE,
    GET_FOIS_DETAILS,
    GET_FOIS_TRACKING_DATA,
    GET_FOIS_STATION_DATA,
    POST_FOIS_FNR,
    GET_FOIS_COUNT,
    GET_FOIS_MOVEMENT_TYPE,
    REMINDER_EMAIL_DATA,
    EDIT_FNR_DATA,
    REQUEST_OTP,
    LOGIN_WITH_OTP,
    LOGIN_WITH_SSO,
    VALIDATE_SSO_USER,
    DOWNLOAD_MILESTONE_TEMP,
    UPLOAD_ORDERS,
    GET_MILESTONE_OVERVIEW_DETAILS,
    GET_MILESTONE_ORDER,
    GET_MILESTONE_DOCKET_LIST,
    GET_VEHICLE_REMARKS,
    CONFIRM_REJECT_VEHICLE,
    DOWNLOAD_LSP_TEMPLATE,
    UPLOAD_LSP_DETAILS,
    UPLOAD_POD_DETAILS,
    GET_SERVICE_PROVIDERS,
    CANCEL_EXCEPTION,
    GET_MILESTONE_RESERVED_LR_LIST,
    UPDATE_EXCEPTION_MILESTONE,
    MOVE_TO_EXCEPTION_MILESTONE_UPDATE,
    GET_REASONS,
    GET_SSO_CONFIG,
    GET_AUDIT_LISTING,
    GET_AUDIT_LIST_COUNT,
    GET_AUDIT_DETAILS,
    COMPLETE_AUDIT,
    SUCCESS_TOAST,
    GET_MOVEMENT_TYPE,
    GET_SHORTAGE_TYPE,
    GET_VEHICLE_DETAILS,
    GENERATE_DELAY_REASON_TEMPLATE,
    UPLOAD_DELAY_REASONS,
    GET_MULTIMODAL_TRACKING_DETAILS,
    VALIDATE_RECAPTCHA,
    VALIDATE_FORGOT_PASSWORD,
    DOWNLOAD_POD_UPLOADED_FILES,
    GET_MILESTONE_LOCATIONS_DROPDOWN,
    GET_TRIP_CONSIGNEE_DROPDOWN,
    GET_BOOKING_ORDER_LOGS,
    GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN,
    GET_API_LOG_TYPES,
    GET_LOG_DETAILS,
    GET_SERVICE_TYPES_DROPDOWN,
    GET_MILESTONE_LR_COUNT_DETAILS,
    GET_BOOKING_ORDER_FAILED_DOWNLOADS,
    GET_USER_SESSION,
    GET_FAST_TAG_ROUTES,
    GET_FAST_TAG_LOGS,
    GET_TRACKED_LOG_DETAILS,
    GET_UNTRACKED_LOG_DETAILS,
} from 'Constants/actionConstants';
import { GET_MOVEMENT_TYPE_URL } from "Utils/api";

sagasManager.addSagaToRoot(function* gisTrackingWatcher() {
    yield all([
        takeLatest(SIGN_IN, signin),
        takeLatest(SIGN_OUT, signout),
        takeLatest(GET_TRIPS, getTrips),
        takeLatest(GET_TRIP_DETAIL, getTripDetail),
        takeLatest(GET_TRANSPORTER_SEACH, searchTransporters),
        takeLatest(GET_PLANT_SEACH, searchPlants),
        takeLatest(GET_ITEMS_SEACH, searchItems),
        takeLatest(GET_CUSTOMER_SEARCH, searchCustomers),
        takeLatest(UPDATE_TRACKING_PREFERENCES, updateTrackingPreferences),
        takeLatest(GET_TRACKING_DETAIL, getTrackingDetails),
        takeLatest(SEARCH_ON_MAP, searchOnMap),
        takeLatest(GET_DETAILED_LOG, getDetailedLog),
        takeLatest(DOWNLOAD_LOCATION_LOGS, downloadLocationLogs),
        takeLatest(GET_LOCATIONS, getLocations),
        takeLatest(GET_DASHBOARD, getDashboard),
        takeLatest(GET_DASHBOARD_DETAILS, getDashboardDetails),
        takeLatest(GET_MULTIPLE_TRIPS, getMultipleTrips),
        takeLatest(ADD_TRIP, addTrip),
        takeLatest(GET_ADD_TRIP_TEMPLATE, downloadAddTripTemplate),
        takeLatest(UPLOAD_TRIPS, uploadTrips),
        takeLatest(POST_MANUAL_TRACKING, postManuatlTracking),
        takeLatest(POST_TRANSPORTER_DETAILS, postTransporterDetails),
        takeLatest(GET_PROVIDERS, getProviders),
        takeLatest(CANCEL_TRIP, updateTripCancellation),
        takeLatest(GET_GPS_VENDORS, getGPSVendors),
        takeLatest(GET_HOLISTICS, getHolistics),
        takeLatest(GET_CURRENT_LOCATION, getCurrentLocation),
        takeLatest(GET_TAT_DETAILS, getTATDetails),
        takeLatest(GET_SHARE_DETAILS, getSharedDetails),
        takeLatest(SAVE_SHARE_DETAILS, postSharedDetails),
        takeLatest(CONFIRM_AT_ORIGIN, confirmAtOrigin),
        takeLatest(GET_ACTION_DETAILS, getActionDetails),
        takeLatest(GET_EXCEPTION_TRIP_DETAILS, getExceptionTripDetails),
        takeLatest(GET_VEHICLE_TYPES, getVehiclesList),
        takeLatest(GET_VEHICLE_BODY_TYPES, getVehicleBodyTypes),
        takeLatest(GET_VEHICLE_DETAIL_OPTIONS, getVehicleDetailOptions),
        takeLatest(GET_VEHICLE_DATA, getVehicleData),
        takeLatest(POST_VEHICLE_DATA, postVehicleData),
        takeLatest(FORGOT_PASSWORD, forgotPassword),
        takeLatest(CHANGE_PASSWORD, changePassword),
        takeEvery(GET_SEEKERS, getSeekers),
        takeLatest(GET_OPERATOR_TYPE, getOpertorTypes),
        takeLatest(GET_NUMBERS_OPERATOR_TYPE, getNumbersOpertorType),
        takeLatest(GET_LOCATION_TYPE_FILTER, getLocationType),
        takeLatest(GET_BROADCAST_MSG, getBroadcastMsg),
        takeLatest(CREATE_LOCATION, createLocation),
        takeLatest(GET_TRIP_STATUS_COUNT, getTripStatusCount),
        takeLatest(DOWNLOAD_TRIP, downloadTrip),
        takeLatest(GET_DO_NO_BASED_ON_FROM_LOC, getDONoBasedOnLocation),
        takeLatest(GET_SCHEDULER_DASHBOARD,getSchedulerDetails),
        takeLatest(GET_BRANCH, getBranchLocationType),
        takeLatest(GET_SCHEDULER_ROUTE_DETAILS,getSchedulerRouteDetails),
        takeLatest(GET_FOIS_TEMPLATE, downloadFOISTrackingTemp),
        takeLatest(POST_FOIS_UPLOAD_FILE, uploadFOISTracking),
        takeLatest(GET_FOIS_DETAILS, getFOISDetails),
        takeLatest(GET_FOIS_TRACKING_DATA, getFOISTrackingData),
        takeLatest(GET_FOIS_STATION_DATA,getFOISStationData),
        takeLatest(POST_FOIS_FNR, postAdhocFNR),
        takeLatest(GET_FOIS_COUNT,getFOISDashboardCount),
        takeLatest(GET_FOIS_MOVEMENT_TYPE, getFOISMovementData),
        takeLatest(REMINDER_EMAIL_DATA, reminderEmail),
        takeLatest(EDIT_FNR_DATA, editFOISTrackingData),
        takeLatest(REQUEST_OTP, requestOTP),
        takeLatest(LOGIN_WITH_OTP, loginWithOTP),
        takeLatest(LOGIN_WITH_SSO, loginWithSSO),
        takeLatest(VALIDATE_SSO_USER, ssoUserValidate),
        takeLatest(DOWNLOAD_MILESTONE_TEMP, downloadMilestoneTemp),
        takeLatest(UPLOAD_ORDERS, uploadOrders),
        takeLatest(GENERATE_DELAY_REASON_TEMPLATE, generateDelayReasonTemplate),
        takeLatest(UPLOAD_DELAY_REASONS, uploadDelayReasons),
        takeLatest(GET_MILESTONE_OVERVIEW_DETAILS, getMilestoneOverviewDetails),
        takeLatest(GET_MILESTONE_LR_COUNT_DETAILS, getMilestoneLrCountDetails),
        takeLatest(GET_AUDIT_LISTING, getAuditListing),
        takeLatest(GET_AUDIT_LIST_COUNT, getAuditListCount),
        takeLatest(GET_AUDIT_DETAILS, getAuditDetails),
        takeLatest(COMPLETE_AUDIT, completeAudit),
        takeLatest(GET_MILESTONE_ORDER, getMilestoneDetails),
        takeLatest(GET_MILESTONE_DOCKET_LIST, getMilestoneDocketList),
        takeLatest(GET_VEHICLE_REMARKS, getVehicleRemarks),
        takeLatest(CONFIRM_REJECT_VEHICLE, confirmRejectVehicle),
        takeLatest(DOWNLOAD_LSP_TEMPLATE, downloadLSPMilestoneTemp),
        takeLatest(UPLOAD_LSP_DETAILS, uploadLSPDetails),
        takeLatest(UPLOAD_POD_DETAILS, uploadPODDetails),
        takeLatest(GET_SERVICE_PROVIDERS, getServiceProvidersList),
        takeLatest(GET_MILESTONE_LOCATIONS_DROPDOWN, getMileStoneLocationsDropdownList),
        takeLatest(CANCEL_EXCEPTION, CancelPickupRequest),
        takeLatest(GET_MILESTONE_RESERVED_LR_LIST, getMilestoneReservedLrList),
        takeLatest(UPDATE_EXCEPTION_MILESTONE, realocateLsp),
        takeLatest(MOVE_TO_EXCEPTION_MILESTONE_UPDATE, movetoException),
        takeLatest(GET_REASONS, getReasonsList),
        takeLatest(GET_SSO_CONFIG, ssoConfig),
        takeLatest(SUCCESS_TOAST, successToast),
        takeLatest(GET_MOVEMENT_TYPE, getMovementType),
        takeLatest(GET_SHORTAGE_TYPE, getShortageType),
        takeLatest(GET_VEHICLE_DETAILS, getVehicleDetails),
        takeLatest(GET_MULTIMODAL_TRACKING_DETAILS, getMultimodalTrackingDetails),
        takeLatest(GET_TRIP_CONSIGNEE_DROPDOWN, getTripConsigneeDropdownList),
        takeLatest(GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN, getTripPlantsAndDepotDropdownList),
        takeLatest(GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN, getTripTransportersDropdownList),
        takeLatest(GET_BOOKING_ORDER_LOGS, downloadPickupRequestLogs),
        takeLatest(GET_API_LOG_TYPES, getApiLogTypesList),
        takeLatest(GET_LOG_DETAILS, getApiLogDetails),
        takeLatest(GET_BOOKING_ORDER_FAILED_DOWNLOADS, getBookingOrderFailedDownlaods),
        takeLatest(GET_USER_SESSION, getUserSession),
        // takeLatest(GET_SERVICE_TYPES_DROPDOWN, getTripServiceTypeDropdownList),
        takeLatest(GET_FAST_TAG_ROUTES, getFastTagDetails),
        takeLatest(GET_FAST_TAG_LOGS, downloadFasttagLogs),
        takeLatest(GET_TRACKED_LOG_DETAILS, getTrackedLogDetails),
        takeLatest(GET_UNTRACKED_LOG_DETAILS, getUntrackedLogDetails),
        takeLatest(DOWNLOAD_POD_UPLOADED_FILES, downloadPODUploadedFiles),
    ]);
});
