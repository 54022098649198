import Sticky from 'Components/Sticky';
import React from 'react';
import { connect } from 'react-redux';

import { Map } from 'Components/Map';
import NoTripMap from 'Components/Map/NoTripMap';
import TripTrackingSideBar from 'Components/TripTrackingSideBar';
import ActionLoader from 'Components/ActionLoader'

import { GET_TRACKING_DETAIL, GET_DETAILED_LOG, SEARCH_ON_MAP, GET_CURRENT_LOCATION, GET_SHARE_DETAILS, SAVE_SHARE_DETAILS, GET_GPS_VENDORS, GET_OPERATOR_TYPE } from 'Constants/actionConstants';
import Location from 'Models/Location';
import { TRACKING_TYPES_MAP } from 'Constants/commonConstants';
import { REMINDER_EMAIL_DATA } from 'Constants/actionConstants';
import styles from './ShareTripTracking.module.scss'
import { withRouter } from 'react-router';
const mapTabFilters = [...TRACKING_TYPES_MAP];

class ShareTripTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state={
      urlBranchId:null
    }
  }
  trackingType = -1;
  searchMap = (search)=> {

        const { dispatch, match } = this.props;
        dispatch({
            type: SEARCH_ON_MAP,
            search,
            trackingType: this.trackingType,
            tripId: match.params.id
        })
  }
  searchDetailedRouteLog = (search, startDate, endDate, trackingType, offset, limit, order_by)=> {
    const { dispatch, match } = this.props;
    dispatch({
        type:GET_DETAILED_LOG, 
        tripId: match.params.id,
        search,
        trackingType,
        startDate,
        endDate,
        offset,
        limit,
        order_by
    });
  }
  searchWithTrackingType = (trackingType)=> {
      const { dispatch, match } = this.props;
      this.trackingType = trackingType;
    dispatch({
        type: GET_TRACKING_DETAIL,
        tripId: match.params.id,
        trackingType
    });
  }
  onSearch = (value)=>{
    localStorage.setItem('teg-component', true);
    location.href="/trips?search=" + value;
  }

  getSharedDetails = () => {
    const { dispatch, match } = this.props;
    const data = {
      trip_id: match.params.id
    }
    dispatch({ type: GET_SHARE_DETAILS, data });
  }

  onSaveSharedDetails = (data) => {
    const { dispatch } = this.props;
    dispatch({ type: SAVE_SHARE_DETAILS, data });
  }

  render() {
    const { tracking, isTrackingLoaded, detailedLog, isDetailedLogLoaded,mapLocation, currentLocation, shareDetails, operatorTypes,match } = this.props; 
    const isTrackingPublicMobile = match.path.includes('mobile')
    if(this.trackingType === -1) {
        this.trackingType = (tracking && tracking.trackingType)?tracking.trackingType:-1;
    }

    const operatorType = mapTabFilters.find(val => {return val.id == tracking.trackingType});
    const queryParams = new URLSearchParams(window.location.search);
    const publicUrl = match.path.includes('tracking-public')
    return (
    //   <Sticky activeLink={'tracking'} onSearch = {this.onSearch}>
    <div className={'main-page'}>
        {
            isTrackingLoaded && 
            <div className={`${styles["trackInfoContainer"]}`}>
               <div  className={`${styles["leftSide"]}`} >
                    <div>
                        {tracking.tripId && <Map
                            searchWithTrackingType= {this.searchWithTrackingType}
                            searchMap={this.searchMap} 
                            tabs={mapTabFilters} 
                            tripForTracking={tracking}
                            markedLocation={mapLocation} 
                            currentLocation={currentLocation}
                            operatorType={operatorType}
                            isMobileScreen={isTrackingPublicMobile}
                            urlBranchId={this.state.urlBranchId}
                        />}
                        {!tracking.tripId && 
                        <NoTripMap 
                            searchWithTrackingType= {this.searchWithTrackingType}
                            searchMap={this.searchMap} 
                            tabs={mapTabFilters} 
                            trackingType = {this.trackingType || -1}
                        />}
                    </div>
                </div>
               {!isTrackingPublicMobile && <div className={`${styles["rightSide"]}`} >
                    <TripTrackingSideBar 
                        isDetailedLogLoaded={isDetailedLogLoaded} 
                        searchDetailedRouteLog={this.searchDetailedRouteLog} 
                        detailedLog={detailedLog || []} 
                        tripForTracking={tracking} 
                        getSharedDetails={this.getSharedDetails}
                        shareDetails={shareDetails}
                        onSaveSharedDetails={this.onSaveSharedDetails}
                        isShare={false}
                        // gpsVendorType={gpsVendorType}
                        urlBranchId={this.state.urlBranchId}
                        operatorTypes={operatorTypes}
                        reminderEmailDetails={this.props.reminderEmailDetails}
                        publicUrl={publicUrl}
                        />
              </div>}
               
            </div>
        }
        
        {!isTrackingLoaded && <ActionLoader fixed={true}/>}
      {/* </Sticky> */}
      </div>
    );
  }
  componentDidCatch(e) {
      console.log(e);
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
      this.getTripDetails();
      this.intervalTripId = setInterval(() => this.getTripDetails(), 300000);
      // dispatch({type: GET_GPS_VENDORS });
      dispatch({ type: GET_OPERATOR_TYPE });
      const tripDetails = this.props.multipleTrips.length ? this.props.multipleTrips : this.props.tripData;
      const trip =  tripDetails.filter((trip) => (trip.tripId || trip.trip_id) ==  match.params.id);
      if( trip.length && trip[0].trackingType == 3 ) {
        this.intervalId = setInterval(() => this.getCurrentLocation(trip), 3600000);
        this.getCurrentLocation(trip);
      }
      // this.getReminderEmail();
  }

  getTripDetails = () => {
    const { dispatch, match } = this.props; 
    const urlParams = new URLSearchParams(window.location.search);
    const seeker_id = urlParams.get('seeker_id');
    const p =urlParams.get('p');
    const branch_id = urlParams.get('branch_id');
    this.setState({urlBranchId:branch_id})
    const queryParams = {
      trip_id: match.params.id,
      // tracking_type:trackingType,
      branch_id,
      p
    }
    dispatch({
      type: GET_TRACKING_DETAIL,
      tripId: match.params.id,
      p : p,
      seeker_id:seeker_id
    });
  }

  // getReminderEmail = () => {
  //   const { dispatch, match } = this.props;
  //   dispatch({
  //     type: REMINDER_EMAIL_DATA,
  //     tripId: match.params.id,
  //   })
  // }

  getCurrentLocation = (trip) => {
    const { dispatch, match } = this.props;
    dispatch({
      type: GET_CURRENT_LOCATION,
      data: {
        vehicle_no: Object.keys(trip[0].vehicleDetails).length ? trip[0].vehicleDetails.vehicleNo : '',
        vendor: this.getVendorDetails(trip[0].driver_details[0].gpsVendor)
      }
    });
  }

  getVendorDetails = (vendorId) => {
    let vendorType= '';
    if(vendorId == 1) {
      vendorType = 'omd';
    } else if(vendorId == 2) {
      vendorType = 'r_track';
    } else if(vendorId == 3) {
      vendorType = 'loconav';
    } else if(vendorId == 4) {
      vendorType = 'pro_track';
    }
    return vendorType;
  }

  componentWillUnmount = () => {
    clearInterval(this.intervalId);
    clearInterval(this.intervalTripId);
  }
}


const mapStateToProps = ({trips}) => {
    return {
        tracking: trips.trackingDetail.data,
        isTrackingLoaded: trips.trackingDetail.isDataLoaded,
        detailedLog: trips.detailedLog.data || [],
        isDetailedLogLoaded: trips.detailedLog.isDataLoaded,
        mapLocation: trips.mapLocation || null,
        trips: trips.trips.data,
        tripData : trips.trips.actualData,
        currentLocation: trips.currentLocation,
        multipleTrips: trips.multipleTrips.data || [],
        shareDetails: trips.shareDetails,
        // gpsVendorType: trips.gpsVendors || [],
        operatorTypes: trips.operatorTypes || [],
        reminderEmailDetails: trips.reminderEmailDetails || []
      }
};


export default connect(mapStateToProps)(withRouter(ShareTripTracking));


