import { getToken, deleteToken, getCompanyType, getCompanyId, getCompanySeekerId } from 'Utils/storage';

export const baseUrl = process.env.BASE_URL; //Prod URL
// const baseUrl = 'http://localhost:9000'; // J5 local
// const baseUrl = "https://dev-apis-vims.transporteg.com";
// const baseUrl = "https://prod-apis-vims.transporteg.com";

/*********** Third Party tracking url *************/
const localUrl = 'http://18.218.67.7:5000';
/**********************************************/

export const INDENTING_URL = process.env.INDENTING_URL;

// const loginbaseUrl = 'http://3.17.204.127:9000';


export const loginBaseUrl = process.env.LOGIN_BASE_URL;
export const SSO_BASE_URL = process.env.SSO_BASE_URL;
// export const SSO_BASE_URL = 'https://dev-apis-auth.transporteg.com';
// export const SSO_BASE_URL = 'http://localhost:5000';
export const SSO_URL = '/sso';
export const SSO_USER_VALIDATE = '/sso/validate/';
export const SSO_DELETE_TOKEN_URL = '/sso/logout/';
export const SSO_CONFIG_URL = '/sso/get-sso-config';

export const SIGN_IN_URL = '/auth/login/';
export const SIGN_OUT_URL = '/auth/logout/';
export const FORGOT_PASSWORD_URL = '/auth/forgot-password/';
export const CHANGE_PASSWORD_URL = '/auth/reset-password/';
export const REQUEST_OTP_URL = '/auth/send-otp/';
export const OTP_SIGNIN_URL = '/auth/verify-otp/';

export const GET_TRIPS_URL = '/api/0.1/tracking/trips';
export const GET_TRIP_DETAIL_URL = '/api/0.1/tracking/trips-details';
export const UPDATE_TRACKING_PREFERENCES_URL = '/api/0.1/tracking/trips';
export const GET_TRACKING_DETAIL_URL = '/api/0.1/tracking/track';
export const SEARCH_ON_MAP_URL = '/api/0.1/tracking/location';
export const GET_DETAILED_LOG_URL = '/api/0.1/tracking/log';
export const DOWNLOAD_LOCATION_LOGS_URL = '/api/0.1/tracking/download-location-logs';

export const SEARCH_DROPDOWN_URL = '/api/0.1/tracking/search';

export const GET_LOCATIONS_URL = '/api/0.1/tracking/plant-search';

export const GET_DASHBOARD_URL = '/api/0.1/tracking/dashboard';
export const GET_DASHBOARD_DETAILS_URL = '/api/0.1/tracking/dashboard-details';
export const GET_MULTIPLE_TRIPS_URL = '/api/0.1/tracking/analytics/';

export const ADD_TRIP_URL = '/api/0.1/tracking/trips';

export const ADD_TRIP_TEMPLATE_URL = '/api/0.1/tracking/generate-template';

export const UPLOAD_TRIP_URL = '/api/0.1/tracking/upload-trips';

export const MANUAL_TRACK_URL = '/api/0.1/tracking/add-location';

export const TRANSPORTER_DETAILS_URL = '/api/0.1/tracking/trips';

export const PROVIDERS_SEARCH_URL = '/api/0.1/tracking/provider-search';

export const CANCEL_TRIP_URL = '/api/0.1/tracking/stop-trip';

export const GPS_VENDORS_URL = '/api/0.1/tracking/gps-vendors';

export const GET_BROADCAST_MSG_URL = '/broadcast/get-messages';

export const HOLISTICS_URL = '/api/0.1/tracking/mis-token';

export const CURRENT_LOCATION_URL = '/api/v1/tracking/vendor/';

export const TAT_URL = '/api/0.1/tracking/get-tat';

export const SHARE_TRIP_DETAILS_URL = '/api/0.1/tracking/share-details';

export const ACTION_DETAILS_URL = '/api/0.1/tracking/dashboard/actionable';

export const EXCEPTION_ALERTS_URL = '/api/0.1/tracking/dashboard/actionable';

export const VEHICLE_TYPES_URL = '/api/0.1/tracking/vehicle-types';

export const VEHICLE_BODY_TYPES_URL = '/api/0.1/tracking/body-types';

export const VEHICLE_DETAIL_OPTIONS_URL = '/api/0.1/tracking/vehicle-details';

export const VEHICLE_DATA_URL = '/api/0.1/tracking/vehicle-data';

export const SEEKERS_SEARCH_URL = '/api/0.1/tracking/seeker-search';

export const GET_OPERATORS_TYPES_URL = '/api/0.1/tracking/telecom-operators';

// export const GET_ALL_OPERATOR_TYPE_URL = '/api/0.1/tracking/get-operator-type/';

export const GET_TRACKING_OPERATORS_URL = '/api/0.1/tracking/get-operator-type/';

export const GET_HAWKEYE_FILTERS = '/api/0.1/tracking/analytics-filters';

export const CREATE_LOCATION_URL = '/api/0.1/tracking/create-location-network/';

export const GET_TRIP_STATUS_COUNT_URL = '/api/0.1/tracking/trips/counts';

export const GET_DO_NO_BASED_ON_FROM_LOC_URL = '/api/0.1/tracking/seq-gen';

export const GET_SCHEDULER_DASHBOARD_URL = '/api/0.1/tracking/secondary-dashboard';

export const GET_BRANCH_URL = '/api/0.1/tracking/dashboard-filters';

export const GET_SCHEDULER_ROUTE_DETAILS_URL = '/api/0.1/tracking/scheduler-search';

export const FOIS_DOWNLOAD_TEMPLATE_URL = '/api/0.1/tracking/generate-fnr-template';

export const POST_FOIS_UPLOAD_URL = '/api/0.1/tracking/upload-fnrs';

export const GET_FOIS_DATA_URL = '/api/0.1/tracking/fnr/listing';

export const GET_FOIS_TRACKING_URL = '/api/0.1/tracking/fnr-tracking/';

export const GET_FOIS_STATION_URL = '/api/0.1/tracking/station-search';

export const POST_ADHOC_FNR_URL = '/api/0.1/tracking/fnr-input';

export const GET_FOIS_DASHBOARD_URL = '/api/0.1/tracking/fnr-dashboard';

export const GET_FOIS_MOVEMENT_TYPE_URL = '/api/0.1/tracking/movement-types';

// export const REMINDER_EMAIL_URL = '/api/0.1/tracking/trip-reminder';

// export const REQUEST_OTP_URL = '/api/0.1/tracking/otp-login';

// export const OTP_SIGNIN_URL = '/api/0.1/tracking/verify-login';
export const TOMTOM_ROUTE_DIAPLAY_URL = '/api/0.1/tracking/get-directions';

export const REMINDER_EMAIL_URL = '/api/0.1/tracking/trip-reminder';

export const GET_CHAIN_DETAILS_URL = '/api/0.1/master/chain/listing';

export const DOWNLOAD_CHAIN_TEMPLATE_URL = '/api/0.1/master/get-chain-template';

export const UPLOAD_CHAIN_URL = '/api/0.1/master/upload-chain';

export const ADD_CHAIN_URL = '/api/0.1/master/chain';

export const GET_USER_DETAILS_URL = '/api/0.1/master/company-user';

export const GET_DETAILS_DROPDOWN_URL = '/api/0.1/master/get-details';

export const SSO_LOGIN_RESPONSE_URL = '/sso/get-session';

export const DOWNLOAD_MILESTONE_TEMP_URL = '/api/0.1/milestone/generate-milestone-template';

export const UPLOAD_ORDERS_URL = '/api/0.1/milestone/upload-milestone-template';

// export const GET_MILESTONE_OVERVIEW_DETAILS_URL = '/api/0.1/milestone/consignment-listing';
export const GET_MILESTONE_OVERVIEW_DETAILS_URL = '/api/0.1/milestone/lr-listing';
export const GET_MILESTONE_LR_COUNT_DETAILS_URL = '/api/0.1/milestone/lr-count';

export const GET_MILESTONE_RESERVED_LR_LIST = '/api/0.1/milestone/reserved-lr-list';
export const UPDATE_EXCEPTION_MILESTONE = '/api/0.1/milestone/re-allocate-lsp';
// export const CANCEL_EXCEPTION = '/api/0.1/milestone/cancel-pickup-request';
export const CANCEL_EXCEPTION = '/api/0.1/milestone/cancel-lr';

// export const GET_AUDIT_LISTING_URL = '/api/0.1/audit/pod-audits'
export const GET_AUDIT_LISTING_URL = '/api/0.1/audit/pod-audits-listing'
export const GET_AUDIT_LIST_COUNT_URL = '/api/0.1/audit/pod-audits-count'

export const GET_AUDIT_DETAILS_URL = '/api/0.1/audit/pod-audit-detail'

export const COMPLETE_AUDIT_URL = '/api/0.1/audit/complete-audit'

export const GET_MILESTONE_ORDER_DETAILS_URL = '/api/0.1/milestone/view-milestone';
export const MOVE_TO_EXCEPTION_MILESTONE_UPDATE = '/api/0.1/milestone/update-milestone';

export const GET_DOCKET_NUMBER_LIST_URL = '/api/0.1/milestone/milestone-search';

export const GET_VEHICLE_REMARKS_URL = '/api/0.1/tracking/vehicle-remark';

export const DOWNLOAD_LSP_TEMPLATE_URL = '/api/0.1/milestone/generate-status-template';

export const UPLOAD_LSP_URL = '/api/0.1/milestone/upload-tracking-status';

export const POD_UPLOAD_URL = '/api/0.1/milestone/pod-upload';

export const GET_SERVICE_PROVIDERS_URL = '/api/0.1/milestone/milestone-vendors';

export const GET_REASONS = '/api/0.1/milestone/delay-types';
// export const GET_REASONS = '/api/0.1/milestone/delay-types?delay_type=3';
export const GET_MOVEMENT_TYPE_URL = '/api/0.1/milestone/movement-types';

export const GET_TYPE_OF_SHORTAGES_URL = '/api/0.1/audit/type-of-shortage';

export const GET_VEHICLE_DETAILS_URL = '/api/0.1/tracking/vehicle-search';

export const GENERATE_DELAY_REASON_TEMPLATE_URL = '/api/0.1/milestone/generate-delay-reason-template';

export const UPLOAD_DELAY_REASONS_URL = '/api/0.1/milestone/upload-delay-reasons';
export const GET_MULTIMODAL_TRACKING_DETAILS_URL ='/api/0.1/tracking/fois-tracking';
export const VALIDATE_RECAPTCHA_URL = '/auth/recaptcha/';
export const DOWNLOAD_EXCEL_URL = '/api/0.1/milestone/get-milestone-pod';
export const GET_MILESTONE_LOCATIONS_DROPDOWN_URL ='/api/0.1/milestone/from-to-location';
export const GET_TRIP_CONSIGNEE_DROPDOWN_URL ='/api/0.1/tracking/consignee-list';
export const GET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN_URL ='/api/0.1/tracking/plant-depot-list';
export const GET_TRIP_TRANSPORTERS_DROPDOWN_URL ='/api/0.1/tracking/transporters-list';

export const GET_BOOKING_ORDER_LOGS_URL ='/api/0.1/milestone/get-booking-order-logs';
export const GET_API_LOG_TYPES_URL = '/api/0.1/milestone/get-api-log-types';
export const GET_LOG_DETAILS_URL = '/api/0.1/milestone/get-lsp-lr-api-logs';

export const GET_BOOKING_ORDER_FAILED_DOWNLOADS_URL = "/api/0.1/milestone/get-booking-order-failed-records";


export const GET_FAST_TAG_ROUTES_URL = '/api/0.1/tracking/fetch-fastag-details';
export const DOWNLOAD_FASTTAG_DETAILS_URL = '/api/0.1/tracking/download-fastag-logs';

export const GET_TRACKED_LOG_DETAILS_URL = '/api/0.1/tracking/tracked-logs';
export const GET_UNTRACKED_LOG_DETAILS_URL = '/api/0.1/tracking/untracked-logs';

export const jsonToUrlParams = (json)=> {
    return Object.keys(json).map(function(k) {
        if (json[k] || k === 'key_vendor') {
            if(k === 'location_type_status') {
                return `${encodeURIComponent(k)}=${encodeURIComponent(`[${json[k]}]`)}`
            } else {
                return encodeURIComponent(k) + '=' + encodeURIComponent(json[k])
            }
        }
        return "";
    }).filter((item)=> {
        return item;
    }).join('&')
}

export const logOutWithToken = () => {
    return new Promise((resolve, reject) => {
       fetch(`${loginBaseUrl}${SIGN_OUT_URL}`, {
          headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json',
             token: getToken(),
             module:3,
             'seeker-id': getCompanySeekerId()
          },
          method: 'POST',
        //   method: 'DELETE',
        //   body: request.headers,
       }).then((response) => {
          if (response.status === 401) {
             deleteToken();
             location.href = "/";
          }
          else if (response.status >= 400) {
             response.json().then((data) => {
                deleteToken();
                // reject(data);
             })
             return;
          }
          response.json().then((data) => {
             deleteToken();
             location.href = "/";
             resolve(data);
          });
       }).catch((e) => {
          console.log('ERROR', e);
          reject(e);
       });
    });
};

export const postDataWithoutToken = (url, data, host)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${host || baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'seeker-id': getCompanySeekerId()
            },
            method: 'POST',
            body: JSON.stringify(data),
        }).then((response)=> {
            if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            console.log('ERROR', e);
            reject(e);
        });
    });
};

export const getDataWithToken = (url, params={}) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}${url}?${jsonToUrlParams(params)}`, {
            headers: {
                token: getToken(),
                'seeker-id': getCompanySeekerId()
            }
        }).then((response) => {
            if (response.status === 401) {
                deleteToken();
                location.href = "/"
            } else if (response.status >= 400) {
                response.json().then((data) => {
                    reject(data);
                })
                return;
            }
            response.json().then((data) => {
                resolve(data);
            });
        }).catch((e) => {
            console.log('ERROR', e);
            reject(e);
        });
    });
};

export const putDataWithToken = (url, data)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'seeker-id': getCompanySeekerId(),
              token:getToken()
            },
            method: 'PUT',
            body: JSON.stringify(data)
        }).then((response)=> {
            if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            console.log('ERROR', e);
            reject(e);
        });
    });
};

export const postDataWithToken = (url, data, host)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${host || baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'seeker-id': getCompanySeekerId(),
              token:getToken()
            },
            method: 'POST',
            body: JSON.stringify(data)
        }).then((response)=> {
            if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            console.log('ERROR', e);
            reject(e);
        });
    });
};


export const uploadFile = (url, file, params = {})=>{
    let form = new FormData();
    if(file && file.length){
        for( let i = 0 ; i < file.length ; i++ ){
            form.append("files", file[i]);
        }
    } else {
        form.append("file", file);
    }

    (Object.keys(params) || []).map(val => {
        form.append(val, params[val]);
    })
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'seeker-id': getCompanySeekerId(),
              token: getToken()
            },
            method: 'POST',
            body: form,
        }).then((response)=> {
            if(response.status > 400) {
                reject(response);
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            console.log('ERROR', e);
            reject(e);
        });
    });
};

export const dowloadWithToken = (url, default_filename, params={}) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}${url}?${jsonToUrlParams(params)}`, {
            headers: {
                token: getToken(),
                'seeker-id': getCompanySeekerId()
            }
        }).then((response) => {
            if (response.status === 401) {
                deleteToken();
                location.href = "/"
            } else if (response.status >= 400) {
                response.json().then((data) => {
                    reject(data);
                })
                return;
            } else {
                resolve(response);
                const file = response.blob();
                file.then(blob => {
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.style = 'display: none';
                    const objectURL = URL.createObjectURL(blob);
                    a.href = objectURL;
                    let filename = default_filename;
                    const contentDisposition = response.headers.get('content-disposition');
                    if (contentDisposition) {
                        const startIndex = contentDisposition.indexOf("filename=") + 9;
                        const endIndex = contentDisposition.length;
                        filename = contentDisposition.substring(startIndex, endIndex);
                    }
                    a.download = filename;
                    a.click();
                    URL.revokeObjectURL(objectURL);
                })
            }
        }).catch((e) => {
            console.log('ERROR', e);
            reject(e);
        });
    });
};

export const getDataWithTokenforLocation  = (url, params={})=> {
    return new Promise((resolve, reject)=> {
        fetch(`${localUrl}${url}?${jsonToUrlParams(params)}`, {
            headers:{
                token:getToken(),
                'seeker-id': getCompanySeekerId()
            }
        }).then((response)=> {
            if(response.status === 401) {
                deleteToken();
                location.href = "/"
            }
            else if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            console.log('ERROR', e);

            reject(e);
        });
    });
};


export const postLoginWithoutToken = (url, data)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${loginBaseUrl}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'seeker-id': getCompanySeekerId()
            },
            method: 'POST',
            body: JSON.stringify(data)
        }).then((response)=> {
            if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            console.log('ERROR', e);
            reject(e);
        });
    });
};

export const deleteDataWithToken = (url, params={})=> {
    // params = {
    //         ...params,
    //         ...getUserDetails()
    //     } 
    return new Promise((resolve, reject)=> {
        fetch(`${baseUrl}${url}?${jsonToUrlParams(params)}`, {
            headers:{
                token:getToken(),
                'seeker-id': getCompanySeekerId()
            },
            method: 'DELETE'
        }).then((response)=> {
            if(response.status === 401) {
                // deleteToken();
                // location.href="/";
                logOutWithToken();
            }
            else if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            console.log('ERROR', e);

            reject(e);
        });
    });
};
export const ssoGetDataWithToken = (url, params={}, genericToken=null) => {
    return new Promise((resolve, reject) => {
        fetch(`${SSO_BASE_URL}${url}?${jsonToUrlParams(params)}`, {
            headers: {
                token: genericToken || getToken(),
                'seeker-id': getCompanySeekerId(),
                module: 3
            }
        }).then((response) => {
            if (response.status === 401) {
                deleteToken();
                location.href = "/"
            } else if (response.status >= 400) {
                response.json().then((data) => {
                    reject(data);
                })
                return;
            }
            response.json().then((data) => {
                resolve(data);
            });
        }).catch((e) => {
            console.log('ERROR', e);
            reject(e);
        });
    });
};

export const ssoPostDataWithToken = (url, data, genericToken=null)=>{
    return new Promise((resolve, reject)=> {
        fetch(`${SSO_BASE_URL}${url}`, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'seeker-id': getCompanySeekerId(),
              token: genericToken || getToken()
            },
            method: 'POST',
            body: JSON.stringify(data)
        }).then((response)=> {
            if(response.status >= 400) {
                response.json().then((data)=>{
                    reject(data);
                })
                return;
            }
            response.json().then((data)=>{
                resolve(data);
            });
        }).catch((e)=> {
            console.log('ERROR', e);
            reject(e);
        });
    });
};

export const postSSODelete = (url, params={}) => {
   return new Promise((resolve, reject) => {
      fetch(`${SSO_BASE_URL}${url}?${jsonToUrlParams(params)}`, {
         headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'seeker-id': getCompanySeekerId(),
            token: getToken()
         },
         method: 'POST'
      }).then((response) => {
         if (response.status === 401) {
            // deleteToken();
            // location.href = "/";
            logOutWithToken();
         }
         else if (response.status >= 400) {
            response.json().then((data) => {
               reject(data);
            })
            return;
         }
         response.json().then((data) => {
            resolve(data);
         });
      }).catch((e) => {
         console.log('ERROR', e);
         reject(e);
      });
   });
};

export const getDataWithoutToken = (url, params={}) => {
    return new Promise((resolve, reject) => {
        fetch(`${loginBaseUrl}${url}?${jsonToUrlParams(params)}`, {
            headers: {
                token: getToken(),
                'seeker-id': getCompanySeekerId()
            }
        }).then((response) => {
            if (response.status === 401) {
                deleteToken();
                location.href = "/"
            } else if (response.status >= 400) {
                response.json().then((data) => {
                    reject(data);
                })
                return;
            }
            response.json().then((data) => {
                resolve(data);
            });
        }).catch((e) => {
            console.log('ERROR', e);
            reject(e);
        });
    });
 };
