import {
    GET_TRIPS_SUCCESS,
    GET_TRIP_DETAIL_SUCCESS,
    GET_TRANSPORTER_SEACH_SUCCESS,
    GET_PLANT_SEACH_SUCCESS,
    GET_ITEMS_SEACH_SUCCESS,
    GET_CUSTOMER_SEARCH_SUCCESS,
    GET_TRACKING_DETAIL_SUCCESS,
    CLEAR_TRIPS,
    CLEAR_TRIP_DETAIL,
    GET_DETAILED_LOG_SUCCESS,
    CLEAR_DETAILED_LOG,
    GET_LOCATIONS_SUCCESS,
    GET_DASHBOARD_SUCCESS,
    CLEAR_DASHBOARD,
    GET_DASHBOARD_DETAILS_SUCCESS,
    CLEAR_DASHBOARD_DETAILS,
    GET_MULTIPLE_TRIPS_SUCCESS,
    CLEAR_MULTIPLE_TRIPS,
    CLEAR_TRACKING_DETAIL,
    SEARCH_ON_MAP_SUCCESS,
    GET_ADD_TRIP_TEMPLATE,
    GET_ADD_TRIP_TEMPLATE_SUCESS,
    POST_MANUAL_TRACKING_SUBMITTING,
    POST_MANUAL_TRACKING_ERROR,
    POST_MANUAL_TRACKING_SUCCESS,
    ADD_TRIP_SUBMITTING,
    ADD_TRIP_SUCCESS,
    POST_TRANSPORTER_DETAILS_SUBMITTING,
    POST_TRANSPORTER_DETAILS_SUCCESS,
    GET_PROVIDERS_SUCCESS,
    CLEAR_PROVIDERS,
    STORE_GPS_VENDORS_DETAILS,
    GET_HOLISTICS_SUCCESS,
    GET_HOLISTICS_SUBMITTING,
    STORE_CURRENT_LOCATION,
    STORE_SHARE_DETAILS,
    STORE_TAT_DETAILS,
    STORE_ACTION_DETAILS,
    STORE_EXCEPTION_TRIP_DETAILS,
    CLEAR_EXCEPTION_TRIP_DETAILS,
    STORE_VEHICLE_TYPES,
    STORE_VEHICLE_BODY_TYPES,
    STORE_VEHICLE_DETAIL_OPTIONS,
    STORE_VEHICLE_DATA,
    GET_SEEKER_SUCCESS,
    CLEAR_SEEKERS,
    SAVE_OPERATOR_TYPE,
    NUMBERS_OPERATOR_TYPE,
    NUMBERS_OPERATOR_LOADING,
    SAVE_LOCATION_TYPE_FILTER,
    DOWLOAD_LOCATION_LOGS_SUCCESS,
    CLEAR_DOWNLOAD_LOG,
    SAVE_TRIP_STATUS_COUNT,
    GET_SCHEDULER_DASHBOARD_SUCCESS,
    SAVE_BRANCH_TYPE_FILTER,
    SAVE_DI_TIME_FILTER,
    GET_SCHEDULER_ROUTE_SUCCESS,
    GET_FOIS_MOVEMENT_TYPE_SUCCESS,
    GET_REMINDER_EMAIL_SUCCESS,
    GET_MILESTONE_OVERVIEW_SUCCESS,
    LOADING_START,
    LOADING_END,
    GET_MILESTONE_ORDER_SUCCESS,
    GET_VEHICLE_REMARKS_SUCCESS,
    GET_SERVICE_PROVIDER_SUCCESS,
    GET_MILESTONE_RESERVED_LR_SUCCESS,
    UPDATE_EXCEPTION_MILESTONE,
    
    GET_AUDIT_LISTING_SUCCESS,
    GET_AUDIT_DETAILS_SUCCESS,
    GET_REASONS_SUCCESS,
    GET_MOVEMENT_TYPE_DATA,
    GET_SHORTAGE_TYPE_DATA,
    GET_VEHICLE_LIST,
    GET_MULTIMODAL_TRACKING_DETAILS_SUCCESS,
    SET_MILESTONE_LOCATIONS_DROPDOWN,
    SET_TRIP_CONSIGNEE_DROPDOWN,
    SET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN,
    SET_TRIP_TRANSPORTERS_DROPDOWN,
    SET_API_LOG_TYPES,
    SET_LOG_DETAILS,
    SET_SERVICE_TYPES_DROPDOWN,
    SET_FAST_TAG_ROUTES,
    SET_FAST_TAG_LOGS
} from 'Constants/actionConstants';
import { GET_GPS_VENDORS } from 'Constants/actionConstants';
const trips = (state={
    trips:{
        data: [],
        isDataLoaded: false,
        containerCount: 0,
        courierCount: 0,
        ftlCount: 0,
        lclCount: 0,
        tripsCount: 0,
        inboundCount: 0,
        outboundCount: 0,
        transporterSearch: [],
        plantSearch: [],
        itemsSearch: [],
        customerSearch: [],
        actualData: []

    },
    tripDetail: {
        data:{},
        isDataLoaded: false
    },
    trackingDetail: {
        data:{},
        isDataLoaded: false
    },
    detailedLog: {
        data:[],
        isDataLoaded: false
    },
    dowloadLogs: {
        isDowloaded: false
    },
    locations: {
        data:[]
    },
    dashboard: {
        data:{
        },
        isDataLoaded: false
    },
    dashboardDetails: {
        inbound:{
            data:[],
            isDataLoaded: false
        },
        outbound: {
            data:[],
            isDataLoaded: false
        },
        priority: {
            data:[],
            isDataLoaded: false
        },
        schedulerDetails: [],
        schedulerCount: 0
    },
    multipleTrips: {
        data:[],
        isDataLoaded: false
    },
    auditListing: [],
    auditDetails: {},
    mapLocation: null,
    templateLink: null,
    transporterDetails: {
        dataSubmitting: false
    },
    manualTracking: {
        dataSubmitting: false,
        dataSubmittedSuccessfully: false,
    },
    addTrip: {
        submitting: false
    },
    providers: {
        data: [],
        isDataLoading: false,
        isDataLoaded: false
    },
    gpsVendors : [],
    holistics: {
        dataSubmitting: false,
        token: "",
        embedCode: "a73629115ab5d742b175f23e"
    },
    currentLocation: {},
    tat: '2',
    notificationSettings: [],
    shareDetails: {},
    actionDetails: {},
    expectionTripDetails: [],
    vehicleServiceTypes: [],
    vehicleData: {},
    vehicleTypes: [],
    vehicleBodyTypes: [],
    seekers: {
        data: [],
        isDataLoading: false,
        isDataLoaded: false
    },
    operatorTypes: [],
    numbersOperatorData: {},
    locationTypeFilter: {},
    tripStatusCount: [],
    tripModeCount: {},
    transportTypeCount: {},
    boundTypeCount: {},
    branchList: [],
    diTimeList: [],
    schedulerRouteDetails: [],
    foisMovementType: [],
    reminderEmailDetails: [],
    milestoneOverview: [],
    milestoneOrder: [],
    serviceProviders: [],
    fromAndToLocationsList:[],
    isLoading: false,
    vehicleRemarks: [],
    serviceProviders: [],
    reservedLrData:[],
    reservedLrData:[],
    reasonsList:[],
    movementTypes: [],
    shortageTypes: [],
    vehicleList: [],
    apiLogTypes:[],
    fastTagDetails: [],
    fastTagLogs: []
}, action)=> {
    let newState;
    if(action.type === CLEAR_TRIPS) {
        newState = {...state};
        newState.trips.data = [];
        newState.trips.isDataLoaded = false;
    } else if(action.type === CLEAR_TRIP_DETAIL) {
        newState = {...state};
        newState.tripDetail.data = [];
        newState.tripDetail.isDataLoaded = false;
    } else if(action.type === CLEAR_DETAILED_LOG) {
        newState = {...state};
        newState.detailedLog.data = [];
        newState.detailedLog.isDataLoaded = false;
    } else if (action.type === DOWLOAD_LOCATION_LOGS_SUCCESS) {
        newState = {...state};
        newState.detailedLog.isDataLoaded = true;
    } else if (action.type === CLEAR_DOWNLOAD_LOG) {
        newState = {...state};
        newState.detailedLog.isDataLoaded = false;
    } else if(action.type === GET_DETAILED_LOG_SUCCESS) {
        newState = {...state};
        newState.detailedLog.data = action.data.locations;
        newState.detailedLog.isDataLoaded = true;
    } else if(action.type === GET_TRIPS_SUCCESS) {
        newState = {...state};
        newState.trips.data = action.data.trips;
        newState.trips.isDataLoaded = true;
        newState.trips.containerCount = action.data.containerCount;
        newState.trips.courierCount = action.data.courierCount;
        newState.trips.ftlCount = action.data.ftlCount;
        newState.trips.lclCount = action.data.lclCount;
        newState.trips.inboundCount = action.data.inboundCount;
        newState.trips.outboundCount = action.data.outboundCount;
        newState.trips.tripsCount = action.data.tripsCount;
        newState.trips.filterCount = action.data.filterCount;
        newState.trips.actualData = action.data.actualData.trips;
    } else if(action.type === GET_TRIP_DETAIL_SUCCESS)  {
        newState = {...state};
        newState.tripDetail.data = action.data.tripDetail;
        newState.tripDetail.isDataLoaded = true;
    } else if(action.type === GET_TRACKING_DETAIL_SUCCESS)  {
        newState = {...state};
        newState.trackingDetail.data = action.data.trackingDetail;
        newState.trackingDetail.isDataLoaded = true;
    } else if(action.type === GET_TRANSPORTER_SEACH_SUCCESS) {
        newState = {...state};
        newState.trips.transporterSearch = action.data;
    }
    else if(action.type === GET_PLANT_SEACH_SUCCESS) {
        newState = {...state};
        newState.trips.plantSearch = action.data;
    }
    else if(action.type === GET_ITEMS_SEACH_SUCCESS) {
        newState = {...state};
        newState.trips.itemsSearch = action.data;
    }
    else if(action.type === GET_CUSTOMER_SEARCH_SUCCESS) {
        newState = {...state};
        newState.trips.customerSearch = action.data;
    }
    else if(action.type === GET_LOCATIONS_SUCCESS)  {
        newState = {...state};
        newState.locations.data = action.data.locations;
    }
    else if(action.type === GET_DASHBOARD_SUCCESS)  {
        newState = {...state};
        newState.dashboard.data = action.data.dashboard;
        newState.dashboard.isDataLoaded = true;
    }
    else if(action.type === GET_DASHBOARD_DETAILS_SUCCESS)  {
        newState = {...state};
        newState.dashboardDetails[action.data.boundType].data = action.data.trips;
        newState.dashboardDetails[action.data.boundType].isDataLoaded = true;
    }
    else if(action.type === GET_MULTIPLE_TRIPS_SUCCESS)  {
        newState = {...state};
        newState.multipleTrips.data = action.data.trips;
        newState.multipleTrips.types = action.data.types;
        newState.multipleTrips.source_data = action.data.source_data;
        newState.multipleTrips.destination_data = action.data.destination_data;
        newState.multipleTrips.isDataLoaded = true;
    }
    else if(action.type === CLEAR_DASHBOARD)  {
        newState = {...state};
        newState.dashboard.isDataLoaded = false;
    }
    else if(action.type === CLEAR_DASHBOARD_DETAILS)  {
        newState = {...state};
        newState.dashboardDetails[action.data.boundType].data = [];
        newState.dashboardDetails[action.data.boundType].isDataLoaded = false;
    }
    else if(action.type === CLEAR_MULTIPLE_TRIPS)  {
        newState = {...state};
        newState.multipleTrips.data = [];
        newState.multipleTrips.isDataLoaded = false;
    }
    else if(action.type === SEARCH_ON_MAP_SUCCESS)  {
        newState = {...state};
        newState.mapLocation = action.mapLocation;
    }
    else if(action.type === CLEAR_TRACKING_DETAIL)  {
        newState = {...state};
        newState.trackingDetail.data = {};
        newState.trackingDetail.isDataLoaded = false;
        newState.mapLocation = null;
    }
    else if(action.type === GET_ADD_TRIP_TEMPLATE)  {
        newState = {...state};
        newState.templateLink = null;
    }
    else if(action.type === GET_ADD_TRIP_TEMPLATE_SUCESS)  {
        newState = {...state};
        newState.templateLink = action.data.templateLink
    }

    else if(action.type === POST_MANUAL_TRACKING_SUCCESS)  {
        newState = {...state};
        newState.manualTracking = {
            dataSubmittedSuccessfully: true,
            dataSubmitting: false,
            error: ''
        }
    }
    else if(action.type === POST_MANUAL_TRACKING_SUBMITTING)  {
        newState = {...state};
        newState.manualTracking = {
            dataSubmittedSuccessfully: false,
            dataSubmitting: true,
            error: ''
        }
    }
    else if(action.type === POST_MANUAL_TRACKING_ERROR)  {
        newState = {...state};
        newState.manualTracking = {
            dataSubmittedSuccessfully: false,
            dataSubmitting: false,
            error: 'Oops! Something Went wrong'
        }
    }
    else if(action.type === ADD_TRIP_SUBMITTING)  {
        newState = {...state};
        newState.addTrip = {
            submitting: true
        }
    }
    else if(action.type === ADD_TRIP_SUCCESS)  {
        newState = {...state};
        newState.addTrip = {
            submitting: false
        }
    }

    else if(action.type === POST_TRANSPORTER_DETAILS_SUBMITTING)  {
        newState = {...state};
        newState.transporterDetails = {
            dataSubmitting: true
        }
    }
    else if(action.type === POST_TRANSPORTER_DETAILS_SUCCESS)  {
        newState = {...state};
        newState.transporterDetails = {
            dataSubmitting: false
        }
    }

    else if(action.type === CLEAR_PROVIDERS)  {
        newState = {...state};
        newState.providers.data = [];
        newState.providers.isDataLoaded = false;
    }
    else if(action.type === GET_PROVIDERS_SUCCESS)  {
        newState = {...state};
        newState.providers.data = action.data.providers;
        newState.providers.isDataLoaded = true;
    }
    else if(action.type === STORE_GPS_VENDORS_DETAILS) {
        newState = {...state};
        newState.gpsVendors = action.data;
    }
    else if(action.type === GET_HOLISTICS_SUBMITTING) {
        newState = {
            ...state,
            holistics: {...state.holistics, dataSubmitting: true}
        };
    }
    else if(action.type === GET_HOLISTICS_SUCCESS) {
        newState = {
            ...state,
            holistics: {...state.holistics,
                dataSubmitting: true,
                token: action.token,
                embedCode: "a73629115ab5d742b175f23e"
            }
        };
    }
    else if(action.type === STORE_CURRENT_LOCATION ) {
        newState = {...state};
        newState.currentLocation = action.data;
    }
    else if(action.type === STORE_TAT_DETAILS) {
        newState = {...state};
        newState.tat = action.data;
        newState.notificationSettings = action.notification_settings;
    } else if(action.type === STORE_SHARE_DETAILS) {
        newState = {...state};
        newState.shareDetails = action.data;
    } else if(action.type === STORE_ACTION_DETAILS) {
        newState = {...state};
        newState.actionDetails = action.data;
        newState.dashboard.isDataLoaded = true;
    } else if(action.type === STORE_EXCEPTION_TRIP_DETAILS) {
        newState = {...state};
        newState.expectionTripDetails = action.data;
        newState.dashboard.isDataLoaded = true;
    } else if(action.type === CLEAR_EXCEPTION_TRIP_DETAILS) {
        newState = {...state};
        newState.expectionTripDetails = [];
        newState.dashboard.isDataLoaded = false;
    } else if(action.type === STORE_VEHICLE_TYPES) {
        newState = {...state};
        newState.vehicleTypes = action.data;
    } else if(action.type === STORE_VEHICLE_BODY_TYPES) {
        newState = {...state};
        newState.vehicleBodyTypes = action.data;
    } else if(action.type === STORE_VEHICLE_DETAIL_OPTIONS) {
        newState = {...state};
        newState.vehicleServiceTypes = action.vehicleServiceTypes;
        newState.vehicleTypes = action.vehicleTypes;
        newState.vehicleBodyTypes = action.bodyTypes;
    } else if(action.type === STORE_VEHICLE_DATA) {
        newState = {...state};
        newState.vehicleData = action.data;
    } else if(action.type === CLEAR_SEEKERS)  {
        newState = {...state};
        newState.seekers.data = [];
        newState.seekers.isDataLoaded = false;
    }
    else if(action.type === GET_SEEKER_SUCCESS)  {
        newState = {...state};
        newState.seekers.data = action.data.seekers;
        newState.seekers.isDataLoaded = true;
    }
    else if(action.type === SAVE_OPERATOR_TYPE) {
        newState = {...state};
        newState.operatorTypes = action.data;
    }
    else if(action.type === NUMBERS_OPERATOR_TYPE) {
        newState = {...state};
        newState.numbersOperatorData = action.data;
        newState.transporterDetails = {dataSubmitting: false};
    }
    else if(action.type === NUMBERS_OPERATOR_LOADING) {
        newState = {...state};
        newState.transporterDetails = {dataSubmitting: true};
    }
    else if(action.type === SAVE_LOCATION_TYPE_FILTER) {
        newState = {...state};
        newState.locationTypeFilter = action.data;
    }
    else if(action.type === SAVE_TRIP_STATUS_COUNT) {
        newState = {...state};
        newState.tripStatusCount = action.data.buckets || [],
        newState.tripModeCount = action.data.trip_mode_count || {};
        newState.transportTypeCount = action.data.transport_type_count || {};
        newState.boundTypeCount = action.data.bound_type_count || {};
    }
    else if(action.type === GET_SCHEDULER_DASHBOARD_SUCCESS) {
        newState = {...state};
        newState.dashboardDetails.schedulerDetails = action.data.trips || [];
        newState.dashboardDetails.schedulerCount = action.data.filter_count || 0;
    }
    else if(action.type === SAVE_BRANCH_TYPE_FILTER) {
        newState = {...state};
        newState.branchList = action.data || [];
    }
    else if(action.type === SAVE_DI_TIME_FILTER) {
        newState = {...state};
        newState.diTimeList = action.data || [];
    }
    else if(action.type === GET_SCHEDULER_ROUTE_SUCCESS){
        newState = {...state};
        newState.schedulerRouteDetails = action.data || [];
    }
    else if(action.type === GET_FOIS_MOVEMENT_TYPE_SUCCESS){
        newState = {...state};
        newState.foisMovementType = action.data || [];
    }
    else if(action.type === GET_REMINDER_EMAIL_SUCCESS){
        newState = {...state};
        newState.reminderEmailDetails = action.data || [];
    }
    else if(action.type === LOADING_START){
        newState = {...state};
        newState.isLoading = true;
    }
    else if(action.type === LOADING_END){
        newState = {...state};
        newState.isLoading = false;
    }
    else if(action.type === GET_MILESTONE_OVERVIEW_SUCCESS){
        newState = {...state};
        newState.milestoneOverview = action.data || [];
        newState.isLoading = false;
    }
    else if(action.type === GET_MILESTONE_ORDER_SUCCESS){
        newState = {...state};
        newState.milestoneOrder = action.data || [];
        newState.isLoading = false;
    }else if(action.type === GET_VEHICLE_REMARKS_SUCCESS){
        newState = {...state};
        newState.vehicleRemarks = action.data || [];
    }
    else if (action.type === GET_AUDIT_LISTING_SUCCESS) {
        newState = { ...state };
        newState.auditListing = action.data || [];
        newState.isLoading = false;
    }
    else if (action.type === GET_AUDIT_DETAILS_SUCCESS) {
        newState = { ...state };
        newState.auditDetails = action.data || {};
        newState.isLoading = false;
    }
    else if(action.type === GET_SERVICE_PROVIDER_SUCCESS){
        newState = {...state};
        newState.serviceProviders = action.data || [];
    }
    else if(action.type === SET_MILESTONE_LOCATIONS_DROPDOWN){
        newState = {...state};
        newState.fromAndToLocationsList = action.data || [];
    }
    else if(action.type === GET_MILESTONE_RESERVED_LR_SUCCESS){
        newState = {...state};
        newState.reservedLrData = action.data || [];
    } else if(action.type === GET_REASONS_SUCCESS){
        newState = {...state};
        newState.reasonsList = action.data || [];
    }
    else if(action.type === GET_MOVEMENT_TYPE_DATA){
        newState = {...state};
        newState.movementTypes = action.data || [];
    }
    else if(action.type === GET_SHORTAGE_TYPE_DATA){
        newState = {...state};
        newState.shortageTypes = action.data || [];
    }
    else if(action.type === GET_VEHICLE_LIST){
        newState = {...state};
        newState.vehicleList = action.data || [];
    }else if(action.type === GET_MULTIMODAL_TRACKING_DETAILS_SUCCESS)  {
        newState = {...state};
        newState.multimodaltrackingDetail = action.data.multimodaltrackingDetail;
        newState.multimodaltrackingDetail.isDataLoaded = true;
    }else if(action.type === SET_TRIP_CONSIGNEE_DROPDOWN){
        newState = {...state};
        newState.tripConsigneeList = action.data || [];
    }else if(action.type === SET_TRIP_PLANTS_AND_DEPOTS_DROPDOWN){
        newState = {...state};
        newState.tripPlantsandDepotsList = action.data || [];
    }
    else if(action.type === SET_TRIP_TRANSPORTERS_DROPDOWN){
        newState = {...state};
        newState.tripTransportersList = action.data || [];
    }else if(action.type === SET_API_LOG_TYPES){
        newState = {...state};
        newState.apiLogTypes = action.data || [];
    }else if(action.type === SET_LOG_DETAILS){
        newState = {...state};
        newState.apiLogData = action.data || [];
    }else if(action.type === 'PAGE_RELOAD'){
        newState = {...state};
    }else if(action.type === SET_FAST_TAG_ROUTES){
        newState = {...state};
        newState.fastTagDetails = action.data || [];
    }else if(action.type === SET_FAST_TAG_LOGS){
        newState = {...state};
        newState.fastTagLogs = action.data || [];
    }
    return newState || state;
};

export default trips;
