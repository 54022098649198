import React from 'react';
import { connect } from 'react-redux';

import SigninForm from 'Containers/SigninForm';

import ActionLoader from 'Components/ActionLoader';
import { getToken, getCompanyType, setCookie, getUserType, getUser } from 'Utils/storage';
import { GET_BROADCAST_MSG } from 'Constants/actionConstants';
import { SEEKER, USER_TYPE } from 'Constants/commonConstants';


class Signin extends React.Component {
  constructor() {
    super();
    this.state = {
      username: '',
      showTag: false
    };
  }

  componentDidMount() {
    const token = getToken();
    const isSSOLogin = getUser().isSSOLogin;
    setTimeout(function() { //Start the timer
      this.setState({showTag: true}) //After 1 second, set render to true
    }.bind(this), 1000)
    if (window.name && window.name.length > 0 && (!token || (JSON.parse(window.name))['tracking-session-token'] !== token) && !(JSON.parse(window.name))['sso-logout-duration']) {
      Object.entries(JSON.parse(window.name)).map(([key, value]) => {
        setCookie(key, value, 200);
      });
      location.reload();
    }

    if (!token) {
      return;
    } else {
      if(isSSOLogin){
        localStorage.setItem('teg-component', false);
        localStorage.setItem('sso-session', true);
        localStorage.setItem('isSSOUser', true);
        localStorage.setItem('teg-sso-session', true);
      }
      this.props.history.push(getCompanyType() === SEEKER ? "/dashboard" : '/trips')
      // this.props.history.push('/transporters')
    }
  }

  render() {
    const { signinError, isSubmitting, ssoConfigData } = this.props;
    return (
      <div style={{background: "rgb(82 138 234 / 85%)", height:"100vh" }}>
        <div className="center pr" style={{width: "40rem", padding: "3rem", transform: "translate(0px, 3rem)", borderRadius: "10px", "box-shadow": "0 0 20px 0px black", background:"#fff"}}>
          <SigninForm error={signinError} showTag={this.state.showTag} ssoConfigData={ssoConfigData} />
          {isSubmitting && <ActionLoader fixed={true} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({authentication, common}) => {
  return {
    signinError: authentication.signin.error,
    isSubmitting: authentication.signin.isSubmitting,
    broadcastMsgs: common.broadcastMsgs,
    ssoConfigData: common.ssoConfigData || {}
  }
};


export default connect(mapStateToProps)(Signin);
