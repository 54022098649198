import Sticky from "Components/Sticky";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import Tabs from "Components/Tabs";
import { Switch, Modal } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
// import  SearchDropdown  from 'Components/SearchDropdown';
import {
  trackingModeLoreal,
  trackingModePantaloons,
  PANTALOONS,
  SEEKER,
  PROVIDER,
  auditTabs,
  BOUND_TYPE_TABS,
} from "Constants/commonConstants";
import { getCompanyType, getUser } from "Utils/storage";
import { DateRangePicker, SingleDatePicker } from "react-dates";
import Moment from 'moment';
import Dropdown from "Components/Dropdown";
import Input from "Components/Input";
import Button from "Components/Button";
import { ReactComponent as DownloadIcon } from "./icons/download.svg";
import { ReactComponent as UploadIcon } from "./icons/upload.svg";
import { ReactComponent as LeftArrow } from "./icons/left.svg";
import { ReactComponent as RightArrow } from "./icons/right.svg";
import { ReactComponent as SideLine } from "./icons/line.svg";
import { ReactComponent as CancelIcon } from "./icons/cancel.svg";
import { ReactComponent as EditIcon } from "./icons/edit.svg";
import { ReactComponent as Warning } from "./icons/warning.svg";
import { ReactComponent as DeleteIcon } from "./icons/delete.svg";
import moment from "moment";
import "react-dates/initialize";
import UploadButton from "Components/UploadButton";
import {
  DOWNLOAD_MILESTONE_TEMP,
  UPLOAD_ORDERS,
  GET_MILESTONE_OVERVIEW_DETAILS,
  GET_MILESTONE_ORDER,
  GET_MILESTONE_DOCKET_LIST,
  DOWNLOAD_LSP_TEMPLATE,
  UPLOAD_LSP_DETAILS,
  UPLOAD_POD_DETAILS,
  GET_SERVICE_PROVIDERS,
  GET_MILESTONE_RESERVED_LR_LIST,
  CANCEL_EXCEPTION,
  UPDATE_EXCEPTION_MILESTONE,
  GET_SERVICE_PROVIDER_SUCCESS,
  GET_AUDIT_LISTING,
  GET_AUDIT_DETAILS,
  COMPLETE_AUDIT,
  TOAST_SUCCESS,
  SUCCESS_TOAST,
  GET_SHORTAGE_TYPE,
  DOWNLOAD_POD_UPLOADED_FILES,
  GET_AUDIT_LIST_COUNT
} from "Constants/actionConstants";
import ActionLoader from "Components/ActionLoader";
import SearchDropdown from "Components/SearchDropdown";
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const SERVICE_PROVIDERS = [
  { name: "Mahindra Logistics Lmt", value: 1 },
  { name: "SAFE EXPRESS", value: 2 },
];

class Audit extends React.Component {
  constructor() {
    super();
    this.state = {
      from_date_search: null,
      to_date_search: null,
      offset: 0,
      limit: 25,
      count: 200,
      bound_type: 1,
      milestoneData: [],
      transporter: "",
      consignor_name: null,
      auditDetailsPopup: false,
      modifyPopup: false,
      showAlert: false,
      orderDetails: [],
      docketList: [],
      type: 1,
      alias: "",
      reserved_lrs: [],
      vendors_arr: null,
      value: "",
      predictions: [],
      // selected_lr:''
      reservedLrData: [],
      tempValue: "",
      lrSuggestions: [],
      setIsShown: false,
      lrnumbersDb: '',
      lsp_details: [],
      order: null,
      podADDTime: null,
      podAPDTime: null,
      isPodBad: false,
      isPodImageBad: false,
      auditForm: {
        lr_number: '',
        vendor_id: 0,
        pod_type: 0,
        bad_pod_reasons: [],
        pod_image_rejected_reason: [],
        lr_id: null
      },
      search: "",
      numPages: null,
      pageNumber: 1,
      previewImageModel: false,
      previewImageUrl: null,
      previewPDFModel: false,
      previewPDFUrl: null,
      pod_box_details_status: {
        is_box_count_accepted: true,
        is_total_wt_accepted: true,
        is_vol_wt_accepted: true,
      },
      countData : null,
      is_grn_available: ""
      
    };
    // this.onChange = this.onChange.bind(this);
  }
  
  componentDidMount() {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
    this.fetchServiceProvider();
    this.auditListing();
  }

  fetchServiceProvider = () => {
    const { dispatch } = this.props;
    const { bound_type } = this.state
    dispatch({
      type: GET_SERVICE_PROVIDERS,
      params: {
        'customer_id' : getUser().company_id,
        'bound_type' : bound_type
      },
    });
  };

  selectTrackingMode = (type) => {
    this.setState({ type, offset: 0, status: undefined }, this.auditTempListing);
  };

  selectBound = (bound_type) => {
    this.setState({
      bound_type,
      offset: 0,
      sort_by: null,
      sort_type: null,
      search: "",
    }, ()=>{
      this.auditListing()
      this.fetchServiceProvider()
    });
  };

  getColor = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === "delivered") {
      return "delivered-color";
    } else if (status === "delayed") {
      return "delayed-color";
    } else {
      return "awaiting-color";
    }
  };

  downloadTemplate = () => {
    const { dispatch } = this.props;
    dispatch({
      type: DOWNLOAD_MILESTONE_TEMP,
    });
  };

  downloadLSPTemplate = () => {
    const { dispatch } = this.props;
    dispatch({
      type: DOWNLOAD_LSP_TEMPLATE,
    });
  };

  uploadOrders = (file) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_ORDERS,
      file,
      onSuccess: () => {
        this.auditListing();
      },
    });
  };

  uploadLSPDetails = (file) => {
    const { dispatch } = this.props;
    dispatch({
      type: UPLOAD_LSP_DETAILS,
      file,
      onSuccess: () => {
        this.auditListing();
      },
    });
  };

  uploadPODDetails = (file, lr_number) => {
    const { dispatch } = this.props;
    const data = {
      lr_number,
    };
    dispatch({
      type: UPLOAD_POD_DETAILS,
      file,
      data,
      onSuccess: () => {
        this.auditListing();
      },
    });
  };

  onPodSwitch = () => {
    this.setState({
      isPodBad: !this.state.isPodBad
    })
  }

  onPodImageSwitch = () => {
    this.setState({
      isPodImageBad: !this.state.isPodImageBad
    })
  }

  onIsOkSwitch = (type) => {
    this.setState({
      pod_box_details_status: {
        ...this.state.pod_box_details_status,
        [type]: !this.state.pod_box_details_status[type]
      }
    })
  }

  auditListing = (download) => {
    const { dispatch } = this.props;
    const {
      limit,
      offset,
      from_date_search,
      to_date_search,
      transporter,
      search,
      type,
      status,
      consignor_name,
      bound_type,
      is_grn_available
    } = this.state;
    
    let params = {
      limit,
      audit_status_type: type,
      offset,
      global_search: search,
      vendor_id: transporter && transporter.value,
      from_date_search: from_date_search && from_date_search.valueOf(),
      to_date_search: to_date_search && to_date_search.valueOf(),
      status_text: status ? status.value : undefined,
      consignor_name,
      bound_type,
      download: download ? 1 : undefined,
      grn_details_exists: is_grn_available && is_grn_available.value
    };
        dispatch({
          type: GET_AUDIT_LISTING,
          params,
          onSuccess: (data) => {
            if(data){
              this.setState({
                auditListing: data.pod_audits,
              })
            }
            dispatch({
              type: GET_AUDIT_LIST_COUNT,
              params,
              onSuccess : (countData) => {
                if (data) {
                  let boundWithCount = BOUND_TYPE_TABS;
                  boundWithCount[0].count = countData.total_audits_count.bound_type_count ? countData.total_audits_count.bound_type_count[1] : 0
                  boundWithCount[1].count = countData.total_audits_count.bound_type_count ? countData.total_audits_count.bound_type_count[2] : 0
        
                  let auditTypesWithCount = auditTabs;
                  auditTypesWithCount[0].count = countData.pod_audit_counts.find((count)=> count.audit_status_type == 1). count;
                  auditTypesWithCount[1].count = countData.pod_audit_counts.find((count) => count.audit_status_type == 2).count;
                  auditTypesWithCount[2].count = countData.pod_audit_counts.find((count) => count.audit_status_type == 3).count;
                  this.setState({
                    auditListing: data.pod_audits,
                    count: data.filter_count,
                    boundTypesWithCount: boundWithCount,
                    auditTypesWithCount: auditTypesWithCount,
                    countData : countData
                  });
                }
              }
            })
          },
        });
    
 
  };

  auditTempListing = (download) => {
    const { dispatch } = this.props;
    const {
      limit,
      offset,
      from_date_search,
      to_date_search,
      transporter,
      search,
      type,
      status,
      consignor_name,
      bound_type,
      is_grn_available,
      countData
    } = this.state;
    
    let params = {
      limit,
      audit_status_type: type,
      offset,
      global_search: search,
      vendor_id: transporter && transporter.value,
      from_date_search: from_date_search && from_date_search.valueOf(),
      to_date_search: to_date_search && to_date_search.valueOf(),
      status_text: status ? status.value : undefined,
      consignor_name,
      bound_type,
      download: download ? 1 : undefined,
      grn_details_exists: is_grn_available && is_grn_available.value
    };
        dispatch({
          type: GET_AUDIT_LISTING,
          params,
          onSuccess: (data) => {
            // dispatch({
            //   type: GET_AUDIT_LIST_COUNT,
            //   params,
            //   onSuccess : (countData) => { 
                if (data) {
                  let boundWithCount = BOUND_TYPE_TABS;
                  boundWithCount[0].count = countData.total_audits_count.bound_type_count ? countData.total_audits_count.bound_type_count[1] : 0
                  boundWithCount[1].count = countData.total_audits_count.bound_type_count ? countData.total_audits_count.bound_type_count[2] : 0
        
                  let auditTypesWithCount = auditTabs;
                  auditTypesWithCount[0].count = countData.pod_audit_counts.find((count)=> count.audit_status_type == 1). count;
                  auditTypesWithCount[1].count = countData.pod_audit_counts.find((count) => count.audit_status_type == 2).count;
                  auditTypesWithCount[2].count = countData.pod_audit_counts.find((count) => count.audit_status_type == 3).count;
                  this.setState({
                    auditListing: data.pod_audits,
                    count: data.filter_count,
                    boundTypesWithCount: boundWithCount,
                    auditTypesWithCount: auditTypesWithCount
                  });
                }
            //   }
            // })
          },
        });
    
 
  };

  getPODLink = (obj, isDownload) => {
    const { dispatch } = this.props;
    const params = {
      lr_id: obj.lr_id,
      is_seeker_pod: obj.pod_details[0].is_seeker_pod ? "1" : "0",
      pod_number: "0"
    }
    dispatch({
      type: DOWNLOAD_POD_UPLOADED_FILES,
      params,
      onSuccess: (data) => {
        if(data && data.length && data[0].pod_link){
          if(isDownload){
            const link = document.createElement('a');
            link.href = data[0].pod_link;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else{
            this.setState({ urlLink: data[0].pod_link})
          }
          
        }
      }
    })
  }

  fetchAuditDetails = (obj) => {
    const { dispatch } = this.props;
    const params = {
      // lr_number: obj.lr_number,
      // vendor_id: parseInt(obj.vendor_id),
      lr_id: obj.lr_id,
    };
    dispatch({
      type: GET_AUDIT_DETAILS,
      params,
      onSuccess: (data) => {
        if(data.audit_completed){
          return dispatch({
            type: SUCCESS_TOAST,
            message: 'Audit already completed'
          })
        } 
        if (data) {
          this.setState({ auditDetailsPopup: true, orderDetails: data, ...(data.actual_delivey_date ? { podADDTime: new Date(Moment(data.actual_delivey_date).format('YYYY-MM-DD HH:mm:ss')).getTime() } : {}), ...(data.pickup_date ? { podAPDTime: new Date(Moment(data.pickup_date).format('YYYY-MM-DD HH:mm:ss')).getTime() } : {}) });
          let othersArray = [];
          let othersArray2 = [];
          const rData = JSON.parse(JSON.stringify(data));
          const imageReasons = JSON.parse(JSON.stringify(data));

          othersArray2.push(imageReasons.bad_pod_image_reasons)
          othersArray2[0].dropdownOptions = othersArray2[0].options;

          othersArray.push(rData.bad_pod_reasons)
          othersArray[0].dropdownOptions = othersArray[0].options;
          this.setState({ badPodReasons: othersArray, badPodImageReasons: othersArray2 })
        }
      },
    });
  };

  getShortageTypes = () => {
    const { dispatch } = this.props;
    dispatch({
      type: GET_SHORTAGE_TYPE
    })
  }

  getDay = (date) => {
    const day = new Date(date).getDay();
    const weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return weekDays[day];
  };

  pagination = (status, count, limit) => {
    let { offset } = this.state;
    if (status === "Next") {
      offset = offset + 25;
    } else if (status === "Previous") {
      offset = offset - 25;
    } else if (status === 'Last') {
      offset = ((Math.floor((count / limit))) * limit)
    } else if (status === 'First') {
      offset = 0
    } else {
      offset = offset;
    }
    this.setState({ offset }, this.auditListing);
  };

  hideOverlay = () => {
    this.setState({
      showAlert: !this.state.showAlert,
    });
  };

  CancelPickup = (obj) => {
    const { dispatch } = this.props;
    const data = {
      current_lr_number: obj.lr_number,
      current_lsp_name: obj.vendor_name,
    };
    dispatch({
      type: CANCEL_EXCEPTION,
      data,
      onSuccess: () => {
        this.auditListing();
      },
    });
    this.setState({
      showAlert: false,
    });
  };

  handleCompleteAuditClick = () => {
    const { auditForm, isPodBad, isPodImageBad, pod_box_details_status, badPodReasons, selectedLRId, podADDTime, podAPDTime, orderDetails,urlLink } = JSON.parse(JSON.stringify(this.state));
    const {dispatch, history} = this.props;
    let data = {};

    if (isPodImageBad){
      data={
        lr_id: auditForm.lr_id ? auditForm.lr_id : selectedLRId,
        pod_image_rejected: true,
        pod_image_rejected_reason: [...auditForm.pod_image_rejected_reason]
      }
    }
    else{
      data = {
       ...auditForm,
       lr_id: auditForm.lr_id ? auditForm.lr_id : selectedLRId
     }
     delete data.pod_image_rejected_reason

     if (!isPodBad){
       data.bad_pod_reasons = [];
       data.pod_type = 1;
       data.pod_image_rejected = false
     } else {
       data.pod_type = 2;
       data.pod_image_rejected = false

       const shortageReasons = data.bad_pod_reasons.filter((reason) => reason.name == "Shortages & Damages");
       const weightMismatchReasons = data.bad_pod_reasons.filter((reason) => reason.name == "Weight Mismatch");
  
       if (shortageReasons && shortageReasons.length){
         data.bad_pod_reasons = data.bad_pod_reasons.filter((reason) => reason.name != "Shortages & Damages");
  
         let shortages = [];
         shortageReasons.map((reason) => {
           shortages.push({
             ...reason,
             shortage_type: reason.remarks ? reason.remarks.remarks_type : reason.shortage_type,
             remarks: reason.remarks ? reason.remarks.remarks_text : null,
             damages: reason.damages == 'Yes' ? true : reason.damages == 'No' ? false : reason.damages
           })
         })
  
         data.bad_pod_reasons.push({
           name: 'Shortages & Damages',
           type: shortageReasons[0].type,
           shortages_damages_list: [
             ...shortages
           ]
         })
  
       }
       if (weightMismatchReasons && weightMismatchReasons.length){
         data.bad_pod_reasons = data.bad_pod_reasons.filter((reason) => reason.name != "Weight Mismatch");
  
         let mismatches = [];
         weightMismatchReasons.map((reason) => {
           mismatches.push({
             ...reason,
             shortage_type: reason.remarks ? reason.remarks.remarks_type : reason.shortage_type,
             remarks: reason.remarks ? reason.remarks.remarks_text : null,
             damages: reason.damages == 'Yes' ? true : reason.damages == 'No' ? false : reason.damages
           })
         })
  
         data.bad_pod_reasons.push({
           name: 'Weight Mismatch',
           type: weightMismatchReasons[0].type,
           weight_mismatch_list: [
             ...mismatches
           ]
         })
  
       }
     }

 
     data.pod_box_details_status = pod_box_details_status
    }
    data.pod_delivery_date = podADDTime ? podADDTime : orderDetails.actual_delivey_date ? new Date(Moment(orderDetails.actual_delivey_date).format('YYYY-MM-DD HH:mm:ss')).getTime() : new Date(Moment().format('YYYY-MM-DD HH:mm:ss')).getTime();
    data.pod_pickup_date = podAPDTime ? podAPDTime : orderDetails.pickup_date ? new Date(Moment(orderDetails.pickup_date).format('YYYY-MM-DD HH:mm:ss')).getTime() : new Date(Moment().format('YYYY-MM-DD HH:mm:ss')).getTime();
    data.modified_type_of_shortage = orderDetails.modified_type_of_shortage ? orderDetails.modified_type_of_shortage : undefined;
    data.existing_type_of_shortage = orderDetails.existing_type_of_shortage ? orderDetails.existing_type_of_shortage : undefined;
    dispatch({
      type: COMPLETE_AUDIT,
      data,
      onSuccess: () => {
        this.setState({ auditDetailsPopup: false, isPodBad: !this.state.isPodBad, isPodImageBad: !this.state.isPodImageBad,
          orderDetails:{},
          urlLink:"",
          previewImageUrl:"",
          pod_box_details_status:  {
            is_box_count_accepted: true,
            is_total_wt_accepted: true,
            is_vol_wt_accepted: true,
          }})
        this.fetchServiceProvider();
        this.auditListing();
        history.push('/audit')
      },
    });

  }

  previewImage = (url) => {
    this.setState({previewImageModel : true, previewImageUrl: url})
  }

  previewPDF = (url) => {
    this.setState({ previewPDFModel: true, previewPDFUrl: url })
  }

  getFileType = (url) => {
    return url.split('.').pop()
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  slidePdf = (val) => {
    const {pageNumber, numPages} = this.state
    if(val == 'Previous' && pageNumber != 1){
      this.setState({pageNumber: pageNumber-1})
    }
    if (val == 'Next' && pageNumber != numPages) {
      this.setState({ pageNumber: pageNumber + 1 })
    }
  }

  onSelectReason = (value, index, deselectIndex) => {
    const {badPodReasons} = JSON.parse(JSON.stringify(this.state))
    let tempAuditForm = JSON.parse(JSON.stringify(this.state.auditForm))
    let tempBadPodReasons = [...badPodReasons];

    let selectedOptions = [];

    let dropdownOptions = [];

    
    if(tempBadPodReasons[index].selectedOptions){
      selectedOptions = [...tempBadPodReasons[index].selectedOptions];
    }

    if(deselectIndex || deselectIndex === 0){

      const deletedOption = selectedOptions.find((option, index) => index === deselectIndex);
      const optionCount = selectedOptions.filter((option) => option.name === deletedOption.name);

      selectedOptions = selectedOptions.filter((option, index)=> index !== deselectIndex);

      let addedOption = tempBadPodReasons[0].options.find((option)=> option.name === deletedOption.name)

      dropdownOptions = tempBadPodReasons[0].dropdownOptions;

      if (!(["shortages_and_damages", "weight_mismatch"].includes(deletedOption.name) && optionCount.length > 1)){
        dropdownOptions.push(addedOption)
      }

      tempBadPodReasons[index] = {
        ...tempBadPodReasons[index],
        selectedOptions,
        dropdownOptions
      }

      this.setState({ badPodReasons: tempBadPodReasons }, () => {
        if(this.state.badPodReasons[0].selectedOptions.length == 0){
         this.setState({
            auditForm: {
            ...tempAuditForm,
            bad_pod_reasons: [],
          }})
        }
        else{
          let bad_pod_reasons = tempAuditForm.bad_pod_reasons;
          bad_pod_reasons = bad_pod_reasons.filter((pod, index) => index !== deselectIndex);
           this.setState({
            auditForm: {
              ...tempAuditForm,
              bad_pod_reasons
            }
          })
        }
      })
    } else {

      for(let i=0; i<tempBadPodReasons[index].options.length; i++) {
        if (tempBadPodReasons[index].options[i].label == value.label){
          selectedOptions.push(tempBadPodReasons[index].options[i])
          tempBadPodReasons[index] = {
            ...tempBadPodReasons[index],
            selectedOptions
          }
          break
        }
      }
      tempBadPodReasons[index].dropdownOptions = tempBadPodReasons[index].dropdownOptions.filter((opt) => opt.label != value.label)
      
      this.setState({ badPodReasons : tempBadPodReasons},()=>{
        let selectedIndex = this.state.badPodReasons[0].selectedOptions.length - 1;
        this.state.badPodReasons[0].selectedOptions[selectedIndex].onSelect.map((sel, index)=>{
          this.handleOnChangeReason(null, 0, selectedIndex, index )
        })
      })

    }
  }

  newReasonDropdown = (type) => {
    let reasons = JSON.parse(JSON.stringify(this.state.orderDetails.bad_pod_reasons))
    let badPodReasons = JSON.parse(JSON.stringify(this.state.badPodReasons));
    const newReason = reasons && reasons.options && reasons.options.find((option) => option.name == type )
    badPodReasons[0].selectedOptions.push(newReason)
    this.setState({ badPodReasons })
  }

  handleOnChangeReason = (event, parentIndex,optionIndex,subOpIndex) => {
    let badPodReasons = JSON.parse(JSON.stringify(this.state.badPodReasons));
    let tempAuditForm = JSON.parse(JSON.stringify(this.state.auditForm));
    let copyOrderDetails = JSON.parse(JSON.stringify(this.state.orderDetails))

    if (!tempAuditForm.lr_number || tempAuditForm.lr_number == '') {
      tempAuditForm.lr_number = this.state.selectedLRNumber;
    }
    if (!tempAuditForm.pod_type) {
      tempAuditForm.pod_type = copyOrderDetails.pod_type;
    }
    if (!tempAuditForm.vendor_id) {
      tempAuditForm.vendor_id = copyOrderDetails.vendor_id;
    }
    if (!tempAuditForm.lr_id || tempAuditForm.lr_id == '') {
      tempAuditForm.lr_id = this.state.selectedLRId;
    }

    let auditFormPodReasons = [];

    let inputValue = event;

    let selectedParentOption = badPodReasons && badPodReasons[parentIndex];

    let selectedOption = selectedParentOption && selectedParentOption.selectedOptions && selectedParentOption.selectedOptions[optionIndex];

    let selectedInput = selectedOption && selectedOption.onSelect && selectedOption.onSelect[subOpIndex];

    if(event && event.target){
      inputValue = event.target.value
    }
    
    if(selectedInput){
      selectedInput.value = inputValue ? inputValue : selectedInput.value ? selectedInput.value : null
    }
    
    badPodReasons[parentIndex] = selectedParentOption;
    
    for(let i = 0; i < badPodReasons.length; i++){
      for(let j = 0; j<badPodReasons[i].selectedOptions.length; j++){
        let payload = {};
        for(let k = 0; k < badPodReasons[i].selectedOptions[j].onSelect.length; k++){
          payload.name = badPodReasons[i].selectedOptions[j].label;
          payload.type = badPodReasons[i].selectedOptions[j].type;
          payload[badPodReasons[i].selectedOptions[j].onSelect[k].name] = badPodReasons[i].selectedOptions[j].onSelect[k].value ? badPodReasons[i].selectedOptions[j].onSelect[k].value : '';
          if ((selectedInput && selectedInput.type == 'select') || (badPodReasons[i].selectedOptions[j].onSelect[k].type == 'select')){
            if (selectedInput.name == 'shortage_type' && inputValue) {
              payload['remarks'] = selectedInput.remarks_list.find((remark)=> remark.remarks_text == inputValue);

              if (badPodReasons[i].selectedOptions[j].onSelect[k].name == "to_be_recovered_from" && badPodReasons[i].selectedOptions[j].onSelect[k].value) {
                payload['to_be_recovered_from_type'] = badPodReasons[i].selectedOptions[j].onSelect[k].options.find((option) => option.label == badPodReasons[i].selectedOptions[j].onSelect[k].value).type;
              }

            } else if (selectedInput.name == "to_be_recovered_from" && inputValue){              
              payload['to_be_recovered_from_type'] = selectedInput.options.find((option) => option.label == inputValue).type;

              if (badPodReasons[i].selectedOptions[j].onSelect[k].name == 'shortage_type' && badPodReasons[i].selectedOptions[j].onSelect[k].value) {
                payload['remarks'] = badPodReasons[i].selectedOptions[j].onSelect[k].remarks_list.find((remark) => remark.remarks_text == badPodReasons[i].selectedOptions[j].onSelect[k].value);
              }

            }
          }
        }
        auditFormPodReasons.push(payload);
      }
    }
    
    tempAuditForm.bad_pod_reasons = [...auditFormPodReasons];

    this.setState({ badPodReasons, auditForm: tempAuditForm })
    
  }

  handleOnChangeBadImageReason = (event, optionIndex, subOpIndex) => {
    let tempAuditForm = JSON.parse(JSON.stringify(this.state.auditForm));
    let tempOrderDetails = JSON.parse(JSON.stringify(this.state.orderDetails));

    if (!tempAuditForm.lr_id || tempAuditForm.lr_id == '') {
      tempAuditForm.lr_id = this.state.selectedLRId;
    }

    let auditFormImageReasons = [];

    let inputValue = event;

      if (event && event.target) {
        inputValue = event.target.value
      }

    let selectedOption = tempOrderDetails.bad_pod_image_reasons && tempOrderDetails.bad_pod_image_reasons.options && tempOrderDetails.bad_pod_image_reasons.options[optionIndex];

    let selectedInput = selectedOption && selectedOption.onSelect && selectedOption.onSelect[subOpIndex];
    
    for (let j = 0; j < tempOrderDetails.bad_pod_image_reasons.options.length; j++) {
        let payload = {};
      for (let k = 0; k < tempOrderDetails.bad_pod_image_reasons.options[j].onSelect.length; k++) {
          if ((selectedInput.remarks_list || selectedInput.options) && inputValue){
            const enteredVal = selectedInput.remarks_list ? selectedInput.remarks_list.find((remark) => remark.remarks_text == inputValue) : selectedInput.options.find((option) => option.value == inputValue);
            payload = {...enteredVal}
          }else{
            payload.name = tempOrderDetails.bad_pod_image_reasons.options[j].label;
            payload.type = tempOrderDetails.bad_pod_image_reasons.options[j].type;
            payload[tempOrderDetails.bad_pod_image_reasons.options[j].onSelect[k].name] = tempOrderDetails.bad_pod_image_reasons.options[j].onSelect[k].value ? tempOrderDetails.bad_pod_image_reasons.options[j].onSelect[k].value : '';
          }
        tempOrderDetails.bad_pod_image_reasons.options[j].onSelect[k].value = inputValue
        }
        auditFormImageReasons.push(payload);
      }

    tempAuditForm.pod_image_rejected_reason = [...auditFormImageReasons];

    this.setState({ auditForm: tempAuditForm, orderDetails: tempOrderDetails })
  }

  onDeleteReason = (index) => {
      this.onSelectReason(null, 0, index)
  }

  arrangeRemarksList = (list) => {
    const updatedList = list
    updatedList.map((item)=>{
      item.label = item.remarks_text;
      item.name = item.remarks_text;
    })
    return updatedList
  }

  getIndexOfLastReason = (list, type) => {
    const shortageReasons = list.filter((reason) => reason.name == type)
    const indexList = [];

    if(shortageReasons.length == 1){
      return list.findIndex((reason) => reason.name == type)
    }

    list.map((reason, index)=>{
      if (reason.name == type){
        indexList.push(index)
      }
    })
    
    return indexList[indexList.length - 1]
  }

  render() {
    // const value = this.state.tempValue;
    // const suggestions = this.state.lrSuggestions;
    // const inputProps = {
    //   placeholder: "Lr number",
    //   value,
    //   onChange: this.onChange,
    // };
    const companyType = getCompanyType();
    const {
      offset,
      limit,
      count,
      auditListing,
      orderDetails,
      order,
      otherReasons,
      docketList,
      selectedRecoveredFrom,
      isPodBad,
      isPodImageBad,
      pod_box_details_status,
      badPodReasons,
      badPodImageReasons
    } = JSON.parse(JSON.stringify(this.state));
    const { isLoading, serviceProviders, shortageTypes } = this.props;
    let providerList = (serviceProviders || []).map((sp) => {
      return {
        name: sp.vendor_name,
        value: sp.vendor_id,
        alias: sp.lsp_alias,
      };
    });
    let shortageList = (shortageTypes || []).map((sl) => {
      return {
        name: sl.shortage_type,
        value: sl.shortage_type,
      };
    });
    const shortageType = ((orderDetails || {}).grn_details || {}).type_of_shortage && ((orderDetails || {}).grn_details || {}).type_of_shortage[0];
    return (
      <>
      <Sticky activeLink={"audit"}>
        {isLoading && <ActionLoader fixed={true} />}
        <div
          className="p5 pr-30"
          style={{ backgroundColor: "#F6F9FF", width: "105%" }}
        >
            <section className="pl-15 mb-10 flex flex-between" style={{ width: '70%' }}>
              <div className="flex-grow mr-10 dashboard-tab col-2">
                <Tabs
                  tabs={this.state.boundTypesWithCount}
                  activeTabId={this.state.bound_type}
                  selectTab={this.selectBound}
                  tabStyle={'milestoneBound'}
                />
              </div>
            </section>
          <section className="pl-15 mb-10 flex flex-start flex-middle" style={{ width: '70%' }}>
            <div className="flex-grow mr-10 dashboard-tab col-2">
              {PANTALOONS.includes(getUser().company_id) ? (
                <Tabs
                  tabs={this.state.auditTypesWithCount}
                  activeTabId={this.state.type}
                  selectTab={this.selectTrackingMode}
                  tabStyle={'auditBound'}
                />
              ) : (
                <Tabs
                  tabs={this.state.auditTypesWithCount}
                  activeTabId={this.state.type}
                  selectTab={this.selectTrackingMode}
                />
              )}
            </div>
              <section className="mb-10 mt-20 ml-40" style={{ height: '35px' }}>
                <div
                  className="wt-40 "
                  onClick={() => this.auditListing(true)}
                >
                  <DownloadIcon className="wt-20 ht-20 pt-5" />
                  <button className="icon-button">Download Data</button>
                </div>
              </section>
          </section>
            <section style={{ width: '100%' }} className="pl-15 mb-10 flex flex-between">
              <div className="dropdown" style={{ position: 'fixed', height: '32px', zIndex: 100000, top: 5, left: 300, width: '700px' }}>
              <SearchDropdown
                isAudit={true}
                onChange={(e) => {
                  this.setState({ search: e }, this.auditListing);
                }}
                onSelect={(value) => {
                  this.setState({ search: value }, this.auditListing);
                }}
                  label={"Search PO No/Inbound No/Invoice No/Docket No"}
                items={docketList || []}
                value={this.state.search}
                hasClearFilter={() => {
                  this.setState({ search: "" }, this.auditListing);
                }}
                selectedClassName={"row-32"}
              />
            </div>
            <div className="flex date-select order-daterange mr-10 wt-270">
              <DateRangePicker
                startDate={
                  this.state.from_date_search
                    ? moment(this.state.from_date_search)
                    : null
                }
                startDateId="startDate"
                endDate={
                  this.state.to_date_search
                    ? moment(this.state.to_date_search)
                    : null
                }
                endDateId="endDate"
                onDatesChange={({ startDate, endDate }) => {
                  let { from_date_search, to_date_search } = this.state;
                  from_date_search = startDate
                    ? new Date(startDate).setHours(0, 0, 0)
                    : new Date(this.state.from_date_search).setHours(0, 0, 0);
                  to_date_search = endDate
                    ? new Date(endDate).setHours(23, 59, 59)
                    : to_date_search;
                  this.setState(
                    { from_date_search, to_date_search, offset: 0 },
                    this.auditListing
                  );
                }}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
                displayFormat={"DD MMM YYYY"}
                noBorder={true}
                minimumNights={0}
                isOutsideRange={() => false}
              />
            </div>
            {this.state.type == 2 && <div className="mr-10 ml-30 wt-180 dropdown">
              <Dropdown
                isAuditFilter={true}
                isReason={true}
                value={this.state.status}
                items={["Bad", "Good"].map((el, index) => {
                  return { value: el, name: el, id: index };
                })}
                label="Status"
                selectedClassName={"col-1"}
                onSelect={(value) =>
                  this.setState({ status: value }, this.auditListing)
                }
              />
            </div>}
            <div className="dropdown mr-10" style={{ width: '300px' }}>
              <SearchDropdown
                isMilestoneFilter={true}
                hideSearch={true}
                onChange={(e) => {
                  this.setState({ consignor_name: e }, this.auditListing);
                }}
                onSelect={(value) => {
                  this.setState(
                    { consignor_name: value },
                    this.milestoneOverview
                  );
                }}
                label={"Enter Vendor"}
                items={docketList || []}
                value={this.state.consignor_name}
                hasClearFilter={() => {
                  this.setState({ consignor_name: "" }, this.auditListing);
                }}
                className={"dropdown"}
              />
            </div>
            {companyType === SEEKER && (
                <div className="mr-10 wt-180 dropdown">
                <Dropdown
                  isAuditFilter={true}
                  isReason={true}
                  value={this.state.transporter}
                  items={providerList}
                  label="Transporter Name"
                  selectedClassName={"col-1"}
                  onSelect={(value) =>
                    this.setState(
                      { transporter: value },
                      this.auditListing
                    )
                  }
                />
              </div>
            )}
            {PANTALOONS.includes(getUser().company_id) && (
                <div className="mr-10 wt-180 dropdown">
                <Dropdown
                  isAuditFilter={true}
                  isReason={true}
                  value={this.state.is_grn_available}
                  items={[{name: 'GRN Not Available', value: '0'},{name: 'GRN Available', value: '1'}]}
                  label="GRN Availability"
                  selectedClassName={"col-1"}
                  onSelect={(value) =>
                    this.setState(
                      { is_grn_available: value },
                      this.auditListing
                    )
                  }
                />
              </div>
            )}
              <div style={{ alignSelf: 'center' }}>
                <button
                  className="icon-button wt-30"
                  onClick={() =>
                    this.setState(
                      {
                        search: "",
                        from_date_search: null,
                        to_date_search: null,
                        transporter: "",
                        consignor_name: "",
                        is_grn_available: ""
                      },
                      this.auditListing
                    )
                  }
                >
                  Clear Filters
                </button>
              </div>
              <div style={{ marginLeft: '20px', width: '700px', justifyContent: 'left', alignSelf: 'center' }} className={`flex`}>
                {
                  (offset > 0) &&
                  <p onClick={() => this.pagination("First", count, limit)} style={{ textDecoration: 'underline' }} className="theme-color curP fBolder ">
                    First
                  </p>
                }
                <div className="ml-10 mr-10 theme-color fBolder">
                  {`${count > 0 ? offset + 1 : 0} - ${count < offset + limit ? count : offset + limit
                    } of ${count}`}
                  <LeftArrow
                    className={
                      offset > 0
                        ? "wt-10 ht-10 ml-10 curP"
                        : "disabled-button wt-10 ht-10 ml-10"
                    }
                    onClick={() => this.pagination("Previous")}
                  />
                  <RightArrow
                    className={
                      count >= offset + limit
                        ? "wt-10 ht-10 ml-10 curP"
                        : "disabled-button wt-10 ht-10 ml-10"
                    }
                    onClick={() => this.pagination("Next")}
                  />
                </div>
                {
                  (count >= offset + limit) &&
                  <p onClick={() => this.pagination("Last", count, limit)} style={{ textDecoration: 'underline' }} className="theme-color curP fBolder">
                    Last
                  </p>
                }
              </div>
          </section>
          <section className="mb-10 flex mt-20">
            <table
              // className="wt-100p"
              style={{
                boxShadow: "-6px -5px 24px 1px rgb(37 38 94 / 10%)",
                width: '95%',
                maxWidth: "100%",
                overflow: "scroll",
                position: "absolute",
              }}
            >
              <thead style={{ backgroundColor: "#e7edfa" }}>
                <tr
                  key={1}
                  style={{
                    borderLeft: "2px solid #e7edfa",
                    borderRight: "2px solid #e7edfa",
                  }}
                >
                  <th className="table-header-details pl-10">Docket No.</th>
                  {/* <th className="table-header-details">Vendor Name</th> */}
                  <th className="table-header-details">Origin</th>
                  <th className="table-header-details">Destination</th>
                    <th className="table-header-details">{this.state.bound_type == 1 ? 'InBound Date' : 'OutBound Date'}</th>
                  <th className="table-header-details">EDD</th>
                  <th className="table-header-details">Delivery Date</th>
                    {this.state.type == 2 && <th className="table-header-details">POD ADD</th>}
                  <th className="table-header-details">Carrier Details</th>
                  {/* <th className="table-header-details">Product Details</th> */}
                  { this.state.type == 2 && <th className="table-header-details">Status</th>}
                  <th className="table-header-details">{this.state.type == 1 ? ' ' : 'Reasons'}</th>
                    {(this.state.type === 2) && (
                      <th className="table-header-details">POD Details</th>
                    )}
                </tr>
              </thead>
              <tbody
                className="ml-5 mr-5"
                style={{ backgroundColor: "#ffffff" }}
              >
                {(auditListing || []).map((obj, index) => {
                  return (
                    <tr
                      key={obj.lr_id}
                      style={{
                        borderLeft: "2px solid #e7edfa",
                        borderRight: "2px solid #e7edfa",
                      }}
                    >
                      {this.state.type === 12 && (
                        <td className="table-body-details">
                          <div
                            className="wt-40 button-classic mb-5"
                            onClick={() =>
                              this.setState(
                                { showAlert: true, order: obj }
                                // this.CancelPickup(obj)
                              )
                            }
                          >
                            <CancelIcon className="wt-20 ht-20 pt-5" />
                            <button className="icon-button">Cancel</button>
                          </div>
                          <div
                            className="wt-40 button-classic"
                            onClick={() =>
                              this.setState(
                                { modifyPopup: true, order: obj }
                                // this.fetchMilestoneList(obj.lr_number)
                              )
                            }
                          >
                            <EditIcon className="wt-20 ht-20 pt-5" />
                            <button className="icon-button">Modify</button>
                          </div>
                        </td>
                      )}

                      <td
                        className="table-body-details pl-10"
                        style={{ color: "blue" }}
                      >
                        {obj.lr_number ? obj.lr_number: '-'}

                      </td>
                      {/* <td className="table-body-details">
                        {(obj.reference_number || []).map((ref) => (
                          <div>{ref}</div>
                        ))}
                        {
                          ((obj.reference_number || []).length == 0) && '-'
                        }
                      </td> */}
                      {/* <td className="table-body-details">
                        {obj.vendor_name ? obj.vendor_name : '-'}
                      </td> */}
                      <td className="table-body-details">{obj.origin ? obj.origin : '-'}</td>
                      <td className="table-body-details">{obj.destination ? obj.destination : '-'}</td>
                      <td
                        className="table-body-details"
                        style={{ padding: "5px" }}
                      >
                        {
                        this.state.bound_type == 1 ?
                        obj.inbound_date ?
                          moment(obj.inbound_date).format("DD MMM, YYYY")
                        :
                        '-'
                        :
                            obj.outbound_date ?
                              moment(obj.outbound_date).format("DD MMM, YYYY")
                              :
                              '-'}
                      </td>
                      <td
                        className="table-body-details"
                        style={{ padding: "5px" }}
                      >
                        {obj.expected_delivery_date ?
                          moment(obj.expected_delivery_date).format(
                            "DD MMM, YYYY"
                          )
                          :
                          '-'
                        }
                      </td>
                      <td
                        className="table-body-details"
                        style={{ padding: "5px" }}
                        >
                        {obj.actual_delivery_date ?
                          moment(obj.actual_delivery_date).format("DD MMM, YYYY")
                          :
                        '-'
                        }
                      </td>
                          {
                            this.state.type == 2 &&
                            <td
                              className="table-body-details"
                              style={{ padding: "5px" }}
                            >
                              {obj.pod_delivery_date ?
                                moment(obj.pod_delivery_date).format("DD MMM, YYYY")
                                :
                                '-'
                              }
                            </td> 
                          }
                      <td className="table-body-details">
                        <div>{obj.vendor_name ? obj.vendor_name : '-'}</div>
                        {/* <div>{obj.carrier_contact}</div> */}
                      </td>
                      {/* <td className="table-body-details">
                        {obj.mode ? `By ${obj.mode}` : "-"}
                      </td> */}
                      {/* <td className="table-body-details">
                        <div>{`No. of Packages: ${obj.no_of_packages ? obj.no_of_packages : "-"
                          }`}</div>
                        <div>{`Weight: ${obj.charged_weight || "-"} ${obj.weight_uom ? obj.weight_uom : "KGs"
                          }`}</div>
                      </td> */}
                      {this.state.type == 2 && 
                      <td className="table-body-details">
                          <div
                            style={{ color: `${obj.pod_status_text == 'BAD' ? 'red' : 'lightgreen'}`}}
                          >
                            {obj.pod_status_text}
                          </div>
                      </td>
                      }
                      <td className="table-body-details">
                      {
                        this.state.type == 1 ?
                              <Button 
                                value={'Audit'}
                                style={{height: '30px', width: '60px'}}
                              onClick={() =>
                                this.setState(
                                  { order: obj, isPodBad: false, isPodImageBad: false, selectedLRNumber: obj.lr_number, selectedLRId: obj.lr_id },
                                  this.fetchAuditDetails(obj),
                                  this.getShortageTypes(),
                                  this.getPODLink(obj, false)
                                )
                              } />
                        :
                        obj.pod_type == 2 ?
                        (
                        <div className="flex flex-column">
                          {
                            obj.bad_pod_reasons && obj.bad_pod_reasons.map((reason) => {
                              return (
                                <p>
                                  {reason.name}
                                </p>
                              )
                            })
                          }
                        </div>
                        )
                        :
                        (
                        <div className="flex flex-column">
                           {
                            obj.pod_image_rejected_reason && obj.pod_image_rejected_reason.map((reason) => {
                              return (
                                <p>
                                  {reason.remarks_text}
                                </p>
                              )
                            })
                          }
                        </div>
                        )
                      }
                      </td>
                      {this.state.type === 2 && (
                        <td className="table-body-details wt-auto">
                          <div>
                            {(obj.pod_details || []).map((pod) => {
                              return (
                                <div className="flex pl-10">
                                    {(pod.pod_list || []).map((list, ind) => {
                                      return (
                                        // <a
                                        //   href={list}
                                        //   download
                                        //   className={"theme-color-light curP"}
                                        // >
                                          <div onClick={() => this.getPODLink(obj, true)} className={"theme-color-light curP"}>
                                          {/* {`POD${ind + 1}${ind + 1 === pod.pod_list.length
                                            ? ""
                                            : ","
                                            }`} */}
                                            {
                                              'POD'
                                            }
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                          </div>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </section>
          {this.state.auditDetailsPopup && !isLoading && (
            <div className="audit-overlay">
              <div
                className="audit-overlay-container modify-popup"
                style={{ width: "100%" }}
              >
                {/* <div
                  className="audit-overlay-close-button fs-20"
                  style={{ float: 'left', display: 'flex' }}
                  onClick={() => {
                    this.setState({
                      auditDetailsPopup: false,
                      previewImageModel: false,
                      podADDTime: null,
                      podAPDTime: null,
                      pod_box_details_status: {
                        is_box_count_accepted: true,
                        is_total_wt_accepted: true,
                        is_vol_wt_accepted: true
                      },
                      orderDetails:{},
                      auditForm: {
                        lr_number: '',
                        vendor_id: 0,
                        pod_type: 2,
                        bad_pod_reasons: [],
                        pod_image_rejected_reason: []
                      } });
                  }}
                >
                  {" "}
                  {"< "}<p className="pl-10">Back</p>
                </div> */}
                <div className="audit-overlay-body create-location-popup">
                    <div style={{ fontSize: '18px', display: 'flex', padding: '5px 0px 8px 0px', position: 'sticky', top: -25, zIndex: '2001', background: 'white', marginTop: '-15px', borderBottom: '2px solid', marginBottom: '5px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className="ml-15 fBolder">
                          LR No: {this.state.selectedLRNumber}
                        </span>
                        <span className="ml-15 fBolder">
                          Carrier Name: {orderDetails.vendor_name}
                        </span>
                      </div>
                      <div className="flex ml-30">
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px', borderRight: '1px solid' }}>
                          <div className="fBolder">
                            {`${this.state.bound_type == 1 ? 'IB Date' : 'OB Date'} `}
                          </div>
                          <div>
                            {
                              this.state.bound_type == 1 ?

                                orderDetails.inbound_date ?
                                  moment(orderDetails.inbound_date).format('DD/MM/YYYY')
                                  :
                                  '-'
                                :
                                orderDetails.outbound_date ?
                                  moment(orderDetails.outbound_date).format('DD/MM/YYYY')
                                  :
                                  '-'

                            }
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px', paddingLeft: '10px', borderRight: '1px solid' }}>
                          <div className="fBolder">
                            APD
                          </div>
                          <div>
                            {
                              orderDetails.pickup_date ?
                                moment(orderDetails.pickup_date).format('DD/MM/YYYY')
                                :
                                '-'
                            }
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px', paddingLeft: '10px', borderRight: '1px solid' }}>
                          <div className="fBolder">
                            EDD
                          </div>
                          <div>
                            {
                              orderDetails.expected_delivery_date ?
                                moment(orderDetails.expected_delivery_date).format('DD/MM/YYYY')
                                :
                                '-'
                            }
                          </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '10px', paddingLeft: '10px' }}>
                          <div className="fBolder">
                            ADD
                          </div>
                          <div>
                            {
                              orderDetails.actual_delivey_date ?
                                moment(orderDetails.actual_delivey_date).format('DD/MM/YYYY')
                                :
                                '-'
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  <div className="flex" style={{ width: '100%'}}>
                  <div className="ml-15" style={{ width: '100%'}}>
                    <div className="fBolder fs-14" style={{ color: 'grey' }}>
                      POD Details
                    </div>

                  <div className='flex'>
                          <div className="flex-col">
                            
                            <table style={{ border: '1px solid #C0C0C0', marginTop: '20px' }}>
                              <thead>
                                <tr>
                                  <th className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>L</th>
                                  <th className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>B</th>
                                  <th className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>H</th>
                                  <th className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>No of Boxes</th>
                                  <th className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>Actual Weight</th>
                                  <th className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>Volumetric Weight</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  orderDetails.dimension && orderDetails.dimension.length ? orderDetails.dimension.map((dim, idx) => {
                                    return (
                                      <tr>
                                        <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>{dim.length}</td>
                                        <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>{dim.breadth}</td>
                                        <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>{dim.height}</td>
                                        <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>{dim.count}</td>
                                        <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>{dim.weight}</td>
                                        <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>{dim.volumetric_weight && dim.volumetric_weight.toFixed(2)}</td>
                                      </tr>
                                    )
                                  })
                                    :
                                    <></>
                                }
                                <tr>
                                  <td colSpan={3} className="tCenter" style={{ padding: '6px 10px 6px 10px', border: '1px solid #C0C0C0' }}>Total: </td>
                                  <td className="tCenter" style={{ padding: '6px 10px 6px 10px', border: '1px solid #C0C0C0' }}>{orderDetails.total_box_details.no_of_boxes}</td>
                                  <td className="tCenter" style={{ padding: '6px 10px 6px 10px', border: '1px solid #C0C0C0' }}>{orderDetails.total_box_details.actual_weight}</td>
                                  <td className="tCenter" style={{ padding: '6px 10px 6px 10px', border: '1px solid #C0C0C0' }}>{orderDetails.total_box_details.volumetric_weight && orderDetails.total_box_details.volumetric_weight.toFixed(2)}</td>
                                </tr>
                                <tr>
                                  <td colSpan={3} className="tCenter" style={{ padding: '6px 10px 6px 10px', border: '1px solid #C0C0C0' }}>Ok / Not Ok : </td>
                                  <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>
                                    <Switch onChange={() => this.onIsOkSwitch('is_box_count_accepted')} style={{ backgroundColor: `${pod_box_details_status.is_box_count_accepted ? "green" : 'red'}` }} />
                                  </td>
                                  <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: '1px solid #C0C0C0' }}>
                                    <Switch onChange={() => this.onIsOkSwitch('is_total_wt_accepted')} style={{ backgroundColor: `${pod_box_details_status.is_total_wt_accepted ? "green" : 'red'}` }} />
                                  </td>
                                  <td className="tCenter" style={{ padding: '2px 10px 2px 10px', border: 'none' }}>
                                    <Switch onChange={() => this.onIsOkSwitch('is_vol_wt_accepted')} style={{ backgroundColor: `${pod_box_details_status.is_vol_wt_accepted ? "green" : 'red'}` }} />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            
                            <div className="flex podADD mt-10 wt-80p">
                              <p className="fBolder fs-18" style={{ paddingTop: '3px' }}>
                                GRN:
                              </p>
                              <Dropdown
                                onSelect={(value) => {
                                  let { orderDetails } = this.state;
                                  orderDetails.modified_type_of_shortage = value.name;
                                  orderDetails.existing_type_of_shortage = shortageType;
                                  this.setState({orderDetails})
                                }}
                                label={orderDetails.modified_type_of_shortage || shortageType}
                                customClass={'audit-input-container ht-30'}
                                items={shortageList}
                                auditSelect
                                // isDisabled={subOp.disabled}
                              />
                            </div>
                            <div className="flex podADD mt-10 non-selectable">
                              <p className="fBolder fs-18" style={{ paddingTop: '3px' }}>
                                POD APD :
                              </p>
                              <SingleDatePicker
                                noBorder={true}
                                displayFormat={"Do, MMM YYYY"}
                                // isOutsideRange={(day) => day.isBefore(Moment(trip.dateTat.gateInDate || trip.dateTat.startedDate).subtract(1, 'day'))}
                                isOutsideRange={(day) => {
                                  // if (orderDetails.pickup_date) {
                                  //   return day.isBefore(Moment(orderDetails.pickup_date))
                                  // } else {
                                  //   return day.isBefore(Moment(new Date()))
                                  // }
                                  return false
                                }}
                                hideKeyboardShortcutsPanel={true}
                                placeholder={"Date"}
                                numberOfMonths={1}
                                date={this.state.podAPDTime ? Moment(this.state.podAPDTime) : orderDetails.pickup_date ? Moment(orderDetails.pickup_date) : Moment(new Date())}
                                onDateChange={(date) => {
                                  // e.preventDefault();
                                  this.setState({
                                    podAPDTime: new Date(Moment(date).format('YYYY-MM-DD HH:mm:ss')).getTime()
                                  })
                                }}
                                focused={this.state.focusedPodAPDTime}
                                onFocusChange={({ focused }) => this.setState({ focusedPodAPDTime: focused })}
                                id="podAPDTime"
                              />
                            </div>

                            <div className="flex podADD mt-10">
                                <p className="fBolder fs-18" style={{paddingTop: '3px'}}>
                                  POD ADD :
                                </p>
                                <SingleDatePicker
                                  noBorder={true}
                                  displayFormat={"Do, MMM YYYY"}
                                  // isOutsideRange={(day) => day.isBefore(Moment(trip.dateTat.gateInDate || trip.dateTat.startedDate).subtract(1, 'day'))}
                                  isOutsideRange={(day) => {
                                    // if(orderDetails.pickup_date){
                                    //   return day.isBefore(Moment(orderDetails.pickup_date))
                                    // }else{
                                    //   return day.isBefore(Moment(new Date()))
                                    // }
                                    return false
                                  }}
                                  hideKeyboardShortcutsPanel={true}
                                  placeholder={"Date"}
                                  numberOfMonths={1}
                                  date={this.state.podADDTime ? Moment(this.state.podADDTime) : orderDetails.actual_delivey_date ? Moment(orderDetails.actual_delivey_date) : Moment(new Date())}
                                  onDateChange={(date) => {
                                    // e.preventDefault();
                                    this.setState({
                                      podADDTime: new Date(Moment(date).format('YYYY-MM-DD HH:mm:ss')).getTime()
                                    })
                                  }}
                                  focused={this.state.focusedPodADDTime}
                                  onFocusChange={({ focused }) => this.setState({ focusedPodADDTime: focused })}
                                  id="podADDTime"
                                />
                            </div>
                            <div className="flex mt-15" style={{ position: 'relative' }}>
                        <p className="fBolder fs-18">
                          POD Image : {isPodImageBad ? 'Bad' : 'Good'}
                        </p>
                        <Switch onChange={this.onPodImageSwitch} style={{ backgroundColor: `${isPodImageBad ? "red" : 'green'}`, marginTop: '2px', marginLeft: '10px' }} />
                      </div>

                    {
                        isPodImageBad &&
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                              {orderDetails.bad_pod_image_reasons && orderDetails.bad_pod_image_reasons.options && orderDetails.bad_pod_image_reasons.options.map((option, oIndex) => {
                                      return (
                                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: `${oIndex > 0 ? '10px' : '0px'}` }}>
                                          <p className="fBolder fs-14 flex flex-middle">
                                            {option.label} {option.onSelect[0].label}
                                          </p>
                                          {
                                            <div className="fs-12 flex ml-15" >

                                              {option.onSelect.map((subOp, index) => {
                                                return (
                                                  <div style={{ display: 'flex', flexDirection: 'column', marginLeft: `${index > 0 ? '20px' : '0px'}` }}>
                                                   
                                                    {
                                                      subOp.type == 'input' ?
                                                        subOp.name == 'remarks' ?
                                                          <div className="audit-input-container" style={{}}>
                                                            <textarea readOnly={!subOp.editable} value={this.state.remarks} onChange={(value) => { this.handleOnChangeBadImageReason(value, oIndex, index) }} style={{ minHeight: '33px', paddingTop: '6px', height: '33px', maxHeight: '50px', minWidth: '150px', width: '500px' }} />
                                                          </div>
                                                          :
                                                          <div className="audit-input-container br253" style={{}}>
                                                            <input defaultValue={subOp.value} readOnly={!subOp.editable} min={0} type={`${option.name == 'others' ? '' : 'number'}`} onChange={(value) => { this.handleOnChangeBadImageReason(value, oIndex, index) }} />
                                                          </div>
                                                        :
                                                        subOp.type == 'select' ?
                                                          <Dropdown
                                                            onSelect={(value) => { this.handleOnChangeBadImageReason(value.label, oIndex, index) }}
                                                            label={subOp.value ? subOp.value : ''}
                                                            customClass={'audit-input-container ht-30'}
                                                            items={subOp.options ? subOp.options : this.arrangeRemarksList(subOp.remarks_list)}
                                                            auditSelect
                                                            isDisabled={subOp.disabled}
                                                          />
                                                          :
                                                          <></>
                                                    }
                                                  </div>
                                                )
                                              })}
                                            </div>
                                          }
                                        </div>
                                      )
                                    })}
                          </div>
                        </div>
                    }
                    
                    <div className="flex mt-15" style={{position:'relative'}}>
                      <p className="fBolder fs-18">
                        POD : {isPodBad ? 'Bad' : 'Good'}
                      </p>
                        <Switch disabled={isPodImageBad} onChange={this.onPodSwitch} style={{ backgroundColor: `${isPodBad ? "red" : 'green'}`, marginTop: '2px', marginLeft: '10px' }} />
                    </div>
                    {
                        isPodBad && !isPodImageBad ?
                        <div style={{display: 'flex', flexDirection:'column', marginTop: '10px'}}>
                          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '5px' }}>
                          {
                                badPodReasons && badPodReasons.map((reason, bIndex)=>{
                                  return (
                                    <>
                                    <div style={{ width: '225px'}}>
                                      
                                        <Dropdown
                                          displayKey={'label'}
                                          onSelect={(value) => { this.onSelectReason(value, bIndex) }}
                                          label={reason.label ? reason.label : ''}
                                          customClass={'audit-input-container ht-40'}
                                          items={reason.dropdownOptions}
                                          auditSelect
                                          isReason
                                        />
                                    </div>
                                      {reason.selectedOptions && reason.selectedOptions.map((option, oIndex) => {
                                        return (
                                          <div style={{ display: 'flex', flexDirection: 'column', marginTop: `${oIndex > 0 ? '10px' : '0px'}` }}>
                                            <p className="fBolder fs-14 flex flex-middle">
                                              {oIndex + 1}.  {option.label} <DeleteIcon onClick={() => { this.onDeleteReason(oIndex) }} style={{ width: '25px', marginLeft: '10px', height: '25px', cursor: 'pointer' }} />
                                            </p>
                                                {
                                                  <div className="fs-12 flex flex-column flex-wrap" >
          
                                                  {option.onSelect.map((subOp, index) => {
                                                    return (
                                                      <div style={{display: 'flex', flexDirection: 'column'}}>
                                                      <p className="fs-12">
                                                        {subOp.label}
                                                      </p>
                                                      {
                                                        subOp.type == 'input' ? 
                                                              subOp.name == 'remarks' ?
                                                              <div className="audit-input-container" style={{}}>
                                                                <textarea readOnly={!subOp.editable} value={this.state.remarks} onChange={(value) => { this.handleOnChangeReason(value, bIndex,oIndex, index)}} style={{ minHeight:'33px', paddingTop:'6px',height:'33px', maxHeight: '50px', minWidth:'150px' ,width: '500px'}} />                                            
                                                                </div>
                                                                :
                                                              <div className="audit-input-container" style={{}}>
                                                                <input defaultValue={subOp.value} readOnly={!subOp.editable} min={0} type={`${option.name == 'others' ? '' : 'number'}`} onChange={(value) => { this.handleOnChangeReason(value, bIndex,oIndex, index) }} />
                                                                </div>
                                                        :
                                                        subOp.type == 'select' ?
                                                        <Dropdown
                                                          marginRightDisabled={true}
                                                          onSelect={(value) => { this.handleOnChangeReason(value.label, bIndex, oIndex, index) }}
                                                          label={subOp.value ? subOp.value : ''}
                                                          customClass={'audit-input-container ht-30'}
                                                          items={subOp.options ? subOp.options : this.arrangeRemarksList(subOp.remarks_list)}
                                                          auditSelect
                                                          isDisabled = {subOp.disabled}
                                                          noNumericInput={true}
                                                        />
                                                        :
                                                        <></>
                                                      }
                                                      </div>
                                                    )
                                                  })}
                                                {["shortages_and_damages", "weight_mismatch"].includes(reason.selectedOptions[oIndex].name) && (oIndex == this.getIndexOfLastReason(reason.selectedOptions, reason.selectedOptions[oIndex].name)) && <Button value={'+ Add'} onClick={() => { this.newReasonDropdown(reason.selectedOptions[oIndex].name) }} style={{ height: '32px', width: '70px', marginTop: '27px', marginLeft: '20px', backgroundColor: 'white', border: '1px solid blue', color: 'blue' }} />}
                                            </div>
                                                }
                                          </div>
                                        )
                                    })}
                                    </>
                                  )
                                })
                          }
                          </div>
                        </div>
                        :
                        <></>
                    }
                    <div className="flex" style={{marginTop:'10px'}}>
              
                      <Button value={'Cancel'} onClick={() => {
                        this.setState({
                          auditDetailsPopup: false, 
                          previewImageModel: false,
                          podADDTime: null,
                          podAPDTime: null,
                          pod_box_details_status:{
                            is_box_count_accepted: true,
                            is_total_wt_accepted: true,
                            is_vol_wt_accepted: true
                          },
                          orderDetails:{},
                          urlLink:"",
                          previewImageUrl:"",
                          auditForm: {
                            lr_number: '',
                            vendor_id: 0,
                            pod_type: 2,
                            bad_pod_reasons: [],
                            pod_image_rejected_reason: []
                          } })}} style={{ height: '32px', width: '70px', marginRight: '20px',marginBottom: '20px' }} />
                    <Button value={'Submit'} onClick={() => { this.handleCompleteAuditClick() }} style={{ height: '32px', width: '120px', marginRight: '20px', marginBottom: '20px' }} />
                    </div>
                  </div>
                  
                  <div className="mt--40" style={{display:"flex",justifyContent:"center"}}>
                     {
                        this.getFileType(orderDetails.pod_file_url) == 'pdf' ?
                          // <div style={{height: 'auto', position: 'relative', marginTop: '20px', marginLeft: '40px' }}>
                            
                            <embed src={orderDetails.pod_file_url + "?zoom=100"} style={{
                                width: '1000px',
                                marginLeft: '20px',
                                marginRight: '20px',
                                position: "relative",
                                overflowX: "scroll",
                                overflowY: 'hidden',
                                border: '1px solid #D0D0D0',
                                zIndex:'2000',
                                height: '500px'
                              }} onClick={() => {
                                let win = window.open();
                                win.document.write('<embed src=' + orderDetails.pod_file_url + ' />');
                              }}/>
                          :
                          <>
                         
                          { !this.state.previewImageModel &&
                          <div style={{ height: '85vh', position: 'relative', marginTop: '20px', marginLeft:'40px' ,display:"flex",justifyContent:"center"}}>
                            <div onClick={() => this.previewImage(orderDetails.pod_file_url)} style={{ position: 'absolute', cursor: 'pointer', textAlign: 'center', padding: '7px', height: 'auto', width: '115px', background: 'rgba(0,0,0,0.7)', color: 'white', zIndex: 100005, marginLeft:"70%"}}>Click to Preview</div>
                          <img src={orderDetails.pod_file_url} alt={'Audit'} style={{width: '90%', cursor: 'pointer'}} />
                          </div>
                          }
                           </>
                     }

                       {this.state.previewImageModel && 
                      <div
                        className="example"
                        style={{
                          padding: '30px',
                          width: '80%',
                          marginLeft: '20px',
                          marginRight: '20px',
                          position: "relative",
                          overflowX: "scroll",
                          overflowY: 'hidden',
                          border: '1px solid #D0D0D0',
                          boxShadow: "-6px -5px 24px 1px rgb(37 38 94 / 10%)",
                          zIndex:'2000'
                        }}
                      >
                        {
                          this.state.previewImageModel &&
                          <ReactPanZoom
                            image={this.state.previewImageUrl}
                            alt="Image alt text"
                          />
                        }
                      </div>
                      }
                     </div>
                      

                    </div>   

                 
                    
                  
                  </div>
                    </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Sticky>
              {/* <Modal
                centered
          open={this.state.previewImageModel || this.state.previewPDFModel}
          zIndex={1000002}
          width={'1200px'}
          bodyStyle={{background: ''}}
          style={{ background: '' }}
          maskStyle={{ background: '' }}
          footer={null}
          onCancel={() => this.setState({ previewImageModel: false, previewPDFModel : false})}
              >
          <div
          className="example"
            style={{
              padding: '30px',
              width: '95%',
              margin: 'auto',
              position: "relative",
              overflowX: "scroll",
              overflowY: 'hidden',
            }}
          >
            {
              this.state.previewImageModel && 
          <ReactPanZoom
              image={this.state.previewImageUrl}
            alt="Image alt text"
          />    
            }
          </div>
              </Modal> */}
              </>
    );
  }
}

const mapStateToProps = ({ trips }) => {
  return {
    milestoneOrder: trips.milestoneOrder || [],
    isLoading: trips.isLoading || false,
    serviceProviders: trips.serviceProviders || [],
    reservedLrData: trips.reservedLrData || [],
    lsp_details: trips.lsp_details || [],
    shortageTypes: trips.shortageTypes || []
  };
};

export default connect(mapStateToProps)(Audit);
