import React, { Component, Fragment } from 'react';
import moment from 'moment';
import className from 'classnames';
import Countdown from 'react-countdown-now';
import { ReactComponent as RedTimer } from 'Pages/Signin/icons/timer-red.svg';


export default class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.setInitialStateData();
    }

    componentWillReceiveProps(nextProps) {
        this.setInitialStateData(false, nextProps);
    }

    setInitialStateData = (hasTimer, nextProps) => {
        let { from, to } = nextProps ? nextProps : this.props;
        this.setState({
            from: moment(from), 
            to: moment(to),
            currentDate: this.props.currentTime?moment(new Date(this.props.currentTime)):moment(new Date())
        }, () => {
            this.updateDiff();
            // this.setupTimer();
        });
        if (this.props.isRelayBid && hasTimer) {
            this.interval = setInterval(() => {
                this.setInitialStateData();
            }, 2000);
        }
    }

    setupTimer = () => {
        this.updateDiff();
        const intervalId = setInterval(() => {
            const duration = moment.duration(this.state.to.diff(this.state.currentDate));
            if(duration.days() == '0' && duration.hours() == '0' && duration.minutes() == '0') {
                clearInterval(this.state.intervalId);
                return;
            }
            this.setState({
                to: this.state.to.subtract(1, 'minutes')
            }, () => {
                this.updateDiff();
            })
        }, 1000 * 60);
        this.setState({
            intervalId: intervalId
        });
    }

    componentWillUnmount() {
        if(this.state.intervalId) {
            clearInterval(this.state.intervalId);
        }
        if (this.props.isRelayBid) {
            clearInterval(this.interval);
        }
    }
    


    updateDiff() {
        const { showInfo } = this.props;
        const prefix = showInfo ? 'Ends in ' : '';
        const duration = moment.duration(this.state.to.diff(this.state.currentDate));
        if(duration.minutes() < 0) {
            clearInterval(this.state.intervalId);
            return;
        }
        const years = duration.years();
        const months = duration.months();
        const days = duration.days();
        const hours = duration.hours();
        const minutes = duration.minutes();
        const seconds = duration.seconds();

        let formattedDiff = "";
        // Difference might be in years!
        if(years > 0) {
            formattedDiff = formattedDiff + (years > 0 ? years + ' Year(s) ': '');
        }
        if(months > 0) {
            formattedDiff = formattedDiff + (months > 0 ? months + ' Month(s) ': '');
        }
        if(days > 0) {
            formattedDiff = formattedDiff + (days > 0 ? days + ' Day(s) ': '');            
        }
        if(hours > 0) {
            formattedDiff = formattedDiff + (hours > 0  ? hours + 'h ': '');      
        }

        if(minutes > 0) {
            formattedDiff = formattedDiff + (minutes > 0  ? minutes + 'm ': '');      
        }

        if(seconds > 0) {
            formattedDiff = formattedDiff + (seconds > 0  ? seconds + 's ': '');   
        }

        this.setState({
            currentDiff: prefix + formattedDiff
        });

    }
    
    render() {
        let { from, to, currentDiff, currentDate } = (this.state || {});    
        const { isOTP } = this.props;
        const originalFrom = this.props.from;
        const originalTo = this.props.to;
        const showInfo = this.props.showInfo;
        const isBiddingExpired = to && currentDate.isAfter(to);
        const isBiddingYetToStart = from && from.isAfter(currentDate);
        const duration = to ? moment(new Date(to)) : moment(new Date());
        if (this.props.isRelayBid && (!duration || duration < originalTo)) {
            this.setInitialStateData(true);
        }

        return (
            <Fragment>
                {originalFrom && originalTo && <div>
                    <RedTimer className="mt-5 mr-5" />
                    {
                        this.state && (isBiddingExpired || currentDiff == '0m' || (showInfo && currentDiff == 'Ends in 0m' )) && <span className="fs-14 lh-15p fBold blur-table-text-color "> Complete </span>
                    }
                    {
                        isOTP && (isBiddingExpired || currentDiff == '0m') && <span className="fs-14 lh-15p fBold blur-table-text-color "> OTP has been expired </span>
                    }
                    {/*
                        this.state && isBiddingYetToStart && !isBiddingExpired && <span className="fs-14 lh-15p fBold blur-table-text-color "> Yet to start </span>
                    */}
                    {
                        this.state && !isBiddingYetToStart && !isBiddingExpired && currentDiff != '0m' && !(showInfo && currentDiff == 'Ends in 0m') &&
                        <span className={className("fs-14 lh-15p fBold", {
                            'error-color': currentDiff && currentDiff.indexOf('h') < 0 && currentDiff.indexOf('Day') < 0
                        })}> 

                        {
                            duration &&
                            <Countdown
                                date={duration.toDate()}
                                renderer={
                                    ({ days, hours, minutes, seconds, completed }) => {
                                        if (!completed && !isOTP) {
                                            return (<span>
                                                Ends in {days} Day(s) {hours}h {minutes}m {seconds}s
                                            </span>);
                                        }
                                        if (!completed && isOTP) {
                                            return (<span>
                                                OTP expires in {minutes}m {seconds}s
                                            </span>);
                                        }
                                        if(completed && isOTP) {
                                            this.props.otpExpired();
                                            
                                        }
                                        return null;
                                    }
                                }
                            />
                        }

                        </span>
                    }
                </div>}
                {
                    this.props.isRelayBid !== true && !(originalFrom && originalTo) && <div className="flex flex-middle">N/A</div>
                }
            </Fragment>
        )
    }
}
