export const GOOGLE_API_KEY = 'AIzaSyBZS3IcyJ42uPzK8z5ZvbK5Fd99j0NqMRE';
export const GENERIC_TOKEN = '5eb7c647379c443a8ab1b6ad1de9403e';

export const DETAILED_LOG_PAGINATION_COUNT = 10;

export const IS_DIRECTION_ENABLED = true;

export const TOAST_HIDE_TIME = 2;

export const PROVIDER = 2;

export const SEEKER = 1;

export const USER_ID = 1;

export const MOBILE_NO = 2;

export const TTBIL = ['b67590f7-a697-4300-99a4-00fad1f2ba98', '52920784121d4d639be3a13ace865e50', 'c8d94d5ce304440e86ee51bfc422f247'];
export const CPLY = ['1925f143bd604e73aeb3d593d1250aa3','8fc235d589bb45c082d93e6f634265f0'];
export const GREENPLY_IDs = ['f9c8c7e230b147ce9ea3122b3d401247'];
export const RAJ_PETRO = ['f5862184a9b648b8ae3c8cfc4dbe242d','344d1da11e894163ba8aa25b041cee37'];

export const AWL = ['872'];

export const GALAXY = ['1320'];

export const LOREAL = ['457f04e3-271b-4489-83f3-89987c1d0df5', 'ae0f03586bf641748e097d1759803635'];
export const PANTALOONS = ['8274e9c6-4402-11ed-b878-0242ac120002','71f16d1bb8604c7d8d2b4821b4ab0122'];
export const CARGILL = ['04d229c7c1934119960cc4f5312dc45d', '5487b3a6065c47e1b764c03b693fc52b'];
export const ABFRL = ['782bd60a53f148b3b342474da97f5be8', '62cd3ea65c60478182b5aa9da9f525fc'];
export const CENTURY_PLY = ['1925f143bd604e73aeb3d593d1250aa3'];
export const DESMET =['c59ad262e2c04e96bc801a5d7b6553df','4dc7f98fd355479cb6ee9a65ef4e9810'];
export const USER_TYPE = {
    ADMIN: 1,
    USER: 2,
    OTHERS: 3,
    SUPER_ADMIN: 4,
    DRIVER: 5,
    AUDIT: 6,
    ZONE: 7
};

export const EAGLE_EYE_TIMER = 10;

export const TRIP_POINTS_LIMIT = 0;

export const TRACKING_TYPES = [
    {
        id: 1,
        value: "App"
    },{
        id: 2,
        value: "Sim"
    }, {
        id: 3,
        value: "Third Party"
    }, {
        id: 4,
        value: "Manual"
    },  {
        id: 5,
        value: "None"
    }
]

export const TRACKING_TYPES_MAP = [
    {
        id: 1,
        value: "App",
        name: "App",
    }, {
        id: 2,
        value: "Sim",
        name: "Sim",
    }, {
        id: 3,
        value: "Third Party",
        name: "Third Party",
    }, {
        id: 4,
        value: "Manual",
        name: "Manual",
    },
]

export const STATUS_TYPES = [
    {
        id: 0,
        value: 'SELECT STATUS TYPE',
        name: 'SELECT STATUS TYPE'
    },
    {
        id: 2,
        value: 'DETAINED',
        name: 'DETAINED'
    },
    {
        id: 3,
        value: 'MISSING',
        name: 'MISSING'
    },
    {
        id: 4,
        value: 'DEVIATED',
        name: 'DEVIATED'
    },
    {
        id: 5,
        value: 'DELAYED',
        name: 'DELAYED'
    },
    {
        id: 6,
        value: 'ON TIME',
        name: 'ON TIME'
    },
    {
        id: 7,
        value: 'UN TRACKED',
        name: 'UN TRACKED'
    },
]

export const MANUAL_LOCATION_TYPES = [
    {
        id: 'Select Location',
        value: 'Select Location',
        name: 'Select Location'
    },
    {
        id: 'National Highway',
        value: 'National Highway',
        name: 'National Highway'
    },
    {
        id: 'Street',
        value: 'Street',
        name: 'Street'
    },
    {
        id: 'Petrol Bunk',
        value: 'Petrol Bunk',
        name: 'Petrol Bunk'
    },
];

export const TURN_AROUND_TIMES = [
    {
        value: "Select Turn Around Time",
        name: "Select Turn Around Time"
    },
    {
        value: "1 Day",
        name: "1 Day"
    },
    {
        value: "2 Days",
        name: "2 Days"
    },
    {
        value: "3 Days",
        name: "3 Days"
    },
    {
        value: "4 Days",
        name: "4 Days"
    },
    {
        value: "5 Days",
        name: "5 Days"
    },
    {
        value: "6 Days",
        name: "6 Days"
    },
    {
        value: "7 Days",
        name: "7 Days"
    },
    {
        value: "8 Days",
        name: "8 Days"
    },
    {
        value: "9 Days",
        name: "9 Days"
    },
    {
        value: "10 Days",
        name: "10 Days"
    }
];

export const LOCATION_TYPES = [
    {
        value: 0,
        name: "Type of location",
    },
    {
        value: 1,
        name: "Manufacturing Plant",
    },
    {
        value: 2,
        name: "Warehouse",
    },
    {
        value: 3,
        name: "Depot"
    },
    {
        value: 4,
        name: "Customer"
    }
];

export const VALUE_UNITS = [
    {
        name: "— Select Units — ",
        value: 0
    },
    {
        name: "Thousand(s)",
        value: 1
    },
    {
        name: "Lakh(s)",
        value: 2
    },
    {
        name: "Crore(s)",
        value: 3
    }
];

export const SERVICE_TYPES = [
    {
        id:0,
        value: 0,
        name: '— Select service —',
    },{
        id:1,
        value: 1,
        name: 'FTL',
    },{
        id:2,
        name: 'LCL',
        value: 2
    },{
        id:3,
        value: 3,
        name: 'Courier',
    },{
        id:4,
        value: 4,
        name: 'Container'
}];

export const RUN_TYPES = [
    {
        id:0,
        value: 0,
        name: '— Select run type — ',
    },{
        id:1,
        value: 1,
        name: "Straight run",
    },{
        id:2,
        value: 2,
        name: "Milk run",
    }
];

export const SUB_REQUEST_TYPES = [
    {
        id:0,
        value: 0,
        name: '— Select sub service request —',
    },
    {
        id:1,
        value: 1,
        name: "Single Mode to Single location",
    },{
        id:2,
        value: 2,
        name: "Single Mode to Multiple location",
    },
    {
        id:3,
        value: 3,
        name: "Multiple Mode to Single location ",
    },{
        id: 4,
        value: 4,
        name: "Single location",
    },
    {
        id:5,
        value: 5,
        name: "Multiple location",
    }
];

export const BOUND_TYPES = [
    {
        id: 0,
        value: 0,
        name: '— Select bound type — '
    },
    {
        id:2,
        value:2,
        name:"Outbound"
    },
    {
        id:1,
        value:1,
        name:"Inbound"
    },
];

export const SHIMPMENT_VALUES = [
    {
        value: "Yes",
        id: 1
    },
    {
        value: "No",
        id: 0
    }
];
export const CARGO_UNITS = [
    {
        value: 0,
        id: 0
    },
    {
        name: 'MT',
        value: "MT",
        id: "MT"
    },
    {
        name: 'KG',
        value: "KG",
        id: "KG"
    },
    {
        name: 'Bin',
        value: "Bin",
        id: "Bin"
    },
    {
        name: 'Trolley',
        value: "Trolley",
        id: "Trolley"
    }
];

export const truck_location_tab = [
    {
        id: 2,
        value: 'New',
        enabled: true
    },
    {
        id: 3,
        value: 'Vehicles placed',
        enabled: false
    },
    {
        id: 4,
        value: 'At Origin',
        enabled: false
    },
    {
        id: 6,
        value: 'In-Transit',
        enabled: false
    },
    {
        id: 5,
        value: 'At Destination',
        enabled: false
    },
    {
        id: 10,
        value: 'Out from Destination',
        enabled: false
    },
    {
        id: 7,
        value: 'Untracked',
        enabled: false
    },
    {
        id: 8,
        value: 'Completed',
        enabled: false
    },
    {
        id: 9,
        value: 'Cancelled',
        enabled: false
    },
    {
        id: 11,
        value: 'Vehicle Rejected',
        enabled: false
    }
]

export const MAP_BUTTONS = [
    { value : 'N',
      to : [
            { value: 'S', name: 'N-S-0', isChecked: false},
            { value: 'E', name: 'N-E-1', isChecked: false},
            { value: 'W', name: 'N-W-2', isChecked: false}
        ]
    },
    { value : 'S',
      to : [
            { value: 'N', name: 'S-N-0', isChecked: false},
            { value: 'E', name: 'S-E-1', isChecked: false},
            { value: 'W', name: 'S-W-2', isChecked: false}
        ]
    },
    { value : 'E',
      to : [
            { value: 'N', name: 'E-N-0', isChecked: false},
            { value: 'S', name: 'E-S-1', isChecked: false},
            { value: 'W', name: 'E-W-2', isChecked: false}
        ]
    },
    { value : 'W',
      to : [
            { value: 'N', name: 'W-N-0', checked: false },
            { value: 'S', name: 'W-S-1', checked: false },
            { value: 'E', name: 'W-E-2', checked: false }
        ]
    }
]

export const subTypeColor = [
    { subType: '41' , color: '#228B22'},
    { subType: '42' , color: '#DB0000'},
    { subType: '61' , color: '#228B22'},
    { subType: '62' , color: '#A5A5A5'},
    { subType: '63' , color: '#000000'},
    { subType: '64' , color: '#FFA500'},
    { subType: '65' , color: '#DB0000'},
    { subType: '51' , color: '#228B22'},
    { subType: '52' , color: '#DB0000'},
    { subType: '101' , color: '#228B22'},
    { subType: '102' , color: '#A5A5A5'},
    { subType: '103' , color: '#000000'},
    { subType: '104' , color: '#FFA500'},
    { subType: '105' , color: '#DB0000'},
    { subType: '106' , color: '#661109'},
]

export const subTypes = [
    { name: 'All', value: '' },
    { name: 'On-time', value: 61 },
    { name: 'Delayed', value: 62 },
    { name: 'Deviated', value: 63 },
    { name: 'Stopped', value: 64 },
    { name: 'Missing', value: 65 },
    
]
    export const pantaloonsException =[
        { name: 'Pickup', value: 'Pickup', id: 1},
        { name: 'Delivery', value: 'Delivery', id: 2},
    ]
    export const pantaloonsDelay =[
        { name: 'Pickup Delay', value: 'Pickup Delay', id: 3},
        { name: 'Delivery Delay', value: 'Delivery Delay', id: 2},
    ]
    export const ModifyExceptions = [
        { name: 'Reserved LR number', value: 'Reserved LR number', id: 0},
        { name: 'Manual LR Number', value: 'Manual LR Number', id: 1}
    ]
export const trackingMode = [
    { name: 'Primary', value: 'Primary', id: 1, name2: 'Long-Haul'},
    { name: 'Secondary', value: 'Secondary', id: 2, name2: 'Short Haul'}
]

export const trackingModeAWL = [
    { name: 'Primary', value: 'Primary', id: 1},
    { name: 'Secondary', value: 'Secondary', id: 2},
    { name: 'MultiModal', value: 'MultiModal', id: 3}
]

export const trackingModeLoreal = [
    { name: 'New', value: 'New', id: 2},
    { name: 'In Progess', value: 'In Progress', id: 6},
    { name: 'Delayed', value: 'Delayed', id: 62 },
    { name: 'Completed', value: 'Completed', id: 8},
    { name: 'Cancelled', value: 'Cancelled', id: 9 }
]
export const trackingModePantaloons = [
    { name: 'New', value: 'New', id: 2},
    { name: 'In Progess', value: 'In Progress', id: 6},
    { name: 'Delayed', value: 'Delayed', id: 62 },
    { name: 'Completed', value: 'Completed', id: 8},
    { name: 'Exception', value: 'Exception', id: 12 },
    { name: 'Cancelled', value: 'Cancelled', id: 9 }
]

export const auditTabs = [
    { name: 'Pending', value: 'Pending', id: 1 },
    { name: 'Audited', value: 'Audited', id: 2 },
    { name: 'On_Hold', value: 'On_Hold', id: 3 },
]

export const configurations = [
    { name: 'Location Network', value: 'Location Network', id: 1},
    { name: 'Users', value: 'Users', id: 2}
]

export const secondary_truck_type = [
    {
        id: 2,
        value: 'New',
        enabled: true
    },
    {
        id: 3,
        value: 'Vehicles placed',
        enabled: false
    },
    {
        id: 6,
        value: 'In-transit',
        enabled: false
    },
    {
        id: 8,
        value: 'Completed',
        enabled: false
    },
    {
        id: 9,
        value: 'Cancelled',
        enabled: false
    }
]

export const BOUND_TYPE_TABS = [
    {
        id: 1,
        value: "Inbound",
        name: "Inbound"
    },
    {
        id: 2,
        value: "Outbound",
        name: "Outbound"
    },
]

export const BOUND_TYPE_TABS_LOREAL = [
    {
        id: 2,
        value: "Outbound",
        name: "Outbound"
    },
]

export const COMPANY_TYPE_TABS = [
    {
        id: 1,
        value: "All",
        name: "All"
    },
    {
        id: 2,
        value: "PT",
        name: "PT"
    },
    {
        id: 3,
        value: "Style Up",
        name: "Style Up"
    },
    {
        id: 4,
        value: "MG",
        name: "MG"
    },
]

export const TRANSPORT_TYPE = [
    {
        id: 1,
        name: 'Domestic',
        value: 'Domestic',
    },
    {
        id: 2,
        name: 'Import',
        value: 'Import',
    },
    {
        id: 3,
        name: 'Export',
        value: 'Export',
    }
]

export const SCHEDULER_STATUS = [
    { name: 'All', value: 1 },
    { name: 'On-time', value: 2 },
    { name: 'Delayed', value: 3 },
    { name: 'In-Transit', value: 4 },
    { name: 'Scheduled', value: 6 },
    { name: 'Not-Started', value: 7 },
    { name: 'Completed', value: 8 },
    { name: 'Cancelled', value: 9 },
    { name: 'Untracked', value: 10 },
]
